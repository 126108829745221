import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reload-component',
  templateUrl: './reload-component.component.html',
  styleUrls: ['./reload-component.component.scss']
})
export class ReloadComponentComponent {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.router.navigate([params.path.replace(/\%2F"/, '/')]);
    });
  }

}

export class ActionType {
    constructor(
        public sOwner?: string,
        public id?: string,
        public sGoogleActionName?: string,
        public sInvocationName?: string,
        public sGoogleProjectId?: string,
        public sLogoLargePath?: string,
        public sLogoSmallPath?: string,
        public sActionType?: string,
        public createdAt?: string,
        public updatedAt?: string
    ) { }
}
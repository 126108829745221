import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RadioContentType } from '../shared/RadioContentType';
import { SkillType } from '../shared/SkillType';
import { MatChipInputEvent } from '@angular/material/chips';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { VcmsDialogPopupComponent } from '../vcms-dialog-popup/vcms-dialog-popup.component';
import {
  checkUrl,
  checkAudioType,
  checkAlexaSynonym,
  checkAlexaCatalogId
} from '../shared/Validations';
import * as ErrorMessages from '../shared/ErrorMessages';
import * as Toolkit from '../shared/Toolkit';
import { Auth } from 'aws-amplify';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppsyncService } from '../services/appsync.service';
import { S3Service } from '../services/s3.service';
import { UpdateRadioContentInput } from '../../API';

/**
 * Main Component
 */

@Component({
  selector: 'app-vcms-radio-content',
  templateUrl: './vcms-radio-content.component.html',
  styleUrls: ['./vcms-radio-content.component.scss']
})
export class VcmsRadioContentComponent implements OnInit {
  formGroup: FormGroup;

  isAdmin = false;
  sCurrentId: string;
  busy: Promise<any>;
  busymessage: string;
  oSkill: SkillType = {};
  oRadioContent: RadioContentType = {};

  getFilePath = Toolkit.getFilePath;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appsyncService: AppsyncService,
    private s3Service: S3Service,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {

    this.initEmptyData();
    this.route.params.subscribe((params = {}) => {
      this.sCurrentId = params.id;
      this.getData();
    });

    this.checkIfAdmin();
  }

  ngOnInit() {
    this.busymessage = 'Speichern...';
  }

  /**
   * Initialization of variables
   */

  async initGeneral(oRadioContent) {
    try {
      if (!oRadioContent.aRadio || oRadioContent.aRadio.length === 0) {
        oRadioContent.aRadio = [await this.appsyncService.emptyNewRadioEntry()];
      }

      const radios = [];
      for (const radioEntry of oRadioContent.aRadio) {
        radios.push(await this.buildRadioFormGroup(radioEntry));
      }

      this.formGroup = this.formBuilder.group({
        id: [oRadioContent.id],
        // sPodcastRssUrl: [oPodcastContent.sPodcastRssUrl, [Validators.required, checkUrl]],
        sCatalogId: [oRadioContent.sCatalogId, [checkAlexaCatalogId]],
        aRadio: this.formBuilder.array(radios),

        sLaunchInitialAudioPath: [oRadioContent.sLaunchInitialAudioPath],
        sLaunchInitialAudioInput: [null, [checkAudioType.bind(this)]],
        sLaunchInitialRepromptAudioPath: [oRadioContent.sLaunchInitialRepromptAudioPath],
        sLaunchInitialRepromptAudioInput: [null, [checkAudioType.bind(this)]],
        sLaunchExtendedAudioPath: [oRadioContent.sLaunchExtendedAudioPath],
        sLaunchExtendedAudioInput: [null, [checkAudioType.bind(this)]],
        sLaunchShortAudioPath: [oRadioContent.sLaunchShortAudioPath],
        sLaunchShortAudioInput: [null, [checkAudioType.bind(this)]],

        sHelpAudioPath: [oRadioContent.sHelpAudioPath],
        sHelpAudioInput: [null, [checkAudioType.bind(this)]],
        sHelpExtendedAudioPath: [oRadioContent.sHelpExtendedAudioPath],
        sHelpExtendedAudioInput: [null, [checkAudioType.bind(this)]],
        sHelpRepromptAudioPath: [oRadioContent.sHelpRepromptAudioPath],
        sHelpRepromptAudioInput: [null, [checkAudioType.bind(this)]],

        sContinueAudioPath: [oRadioContent.sContinueAudioPath],
        sContinueAudioInput: [null, [checkAudioType.bind(this)]],
        sContinueExtendedAudioPath: [oRadioContent.sContinueExtendedAudioPath],
        sContinueExtendedAudioInput: [null, [checkAudioType.bind(this)]],

        sNoMoreStreamsAudioPath: [oRadioContent.sNoMoreStreamsAudioPath],
        sNoMoreStreamsAudioInput: [null, [checkAudioType.bind(this)]],

        sErrorAudioPath: [oRadioContent.sErrorAudioPath],
        sErrorAudioInput: [null, [checkAudioType.bind(this)]],
        sNotSupportedAudioPath: [oRadioContent.sNotSupportedAudioPath],
        sNotSupportedAudioInput: [null, [checkAudioType.bind(this)]],
        sUnknownStreamAudioPath: [oRadioContent.sUnknownStreamAudioPath],
        sUnknownStreamAudioInput: [null, [checkAudioType.bind(this)]],
        sFallbackAudioPath: [oRadioContent.sFallbackAudioPath],
        sFallbackAudioInput: [null, [checkAudioType.bind(this)]],

        sStopAudioPath: [oRadioContent.sStopAudioPath],
        sStopAudioInput: [null, [checkAudioType.bind(this)]]
      });

      await this.checkIfAdmin();
    } catch (oError) { }
  }

  async buildRadioFormGroup(oRadioEntry): Promise<FormGroup> {
    const formGroup = this.formBuilder.group({
      sName: [oRadioEntry.sName, [Validators.required]],
      aSynonyms: [oRadioEntry.aSynonyms],
      sSynonym: ['', [checkAlexaSynonym.bind(this)]],
      sUrl: [oRadioEntry.sUrl, [Validators.required, checkUrl]],
      sImagePath: [oRadioEntry.sImagePath],
      oImageInput: oRadioEntry.sImagePath ? [null] : [null, [Validators.required]],
      sPreviewImageSrc: [null]
    });
    await this.setFileUrl(null, formGroup);
    return formGroup;
  }

  /**
   * Getter
   */

  get aRadio(): FormArray {
    return this.formGroup.get('aRadio') as FormArray;
  }

  /**
   * Radio methods
   */

  async addRadio(): Promise<void> {
    this.aRadio.push(await this.buildRadioFormGroup(await this.appsyncService.emptyNewRadioEntry()));
  }

  removeRadio(index): void {
    this.aRadio.removeAt(index);
  }

  dropRadio(event: CdkDragDrop<string[]>) {
    const moveRadio = () => {
      const aRadioFormArray = this.formGroup.controls.aRadio as FormArray;
      const oRadioFormGroup = aRadioFormArray.at(event.previousIndex);
      aRadioFormArray.removeAt(event.previousIndex);
      aRadioFormArray.insert(event.currentIndex, oRadioFormGroup);
    };

    if (event.previousIndex !== event.currentIndex) {
      if (event.previousIndex === 0 || event.currentIndex === 0) {
        const radioValues = this.formGroup.value.aRadio;
        const oldMain = radioValues[0].sName;
        const newMain = radioValues[event.previousIndex === 0 ? 1 : event.previousIndex].sName;

        const dialogRef = this.dialog.open(VcmsDialogPopupComponent, {
          width: '400px',
          data: {
            content: `
              <p>Durch diese Aktion wird der Hauptstream des Skills
              von <b>${oldMain ? oldMain : 'leer'}</b> auf <b>${newMain ? newMain : 'leer'}</b> geändert.</p>
              <br>
              <p>Willst du diese Änderung wirklich durchführen?</p>
            `,
            yesText: 'Ja',
            noText: 'Nein'
          }
        });

        dialogRef.afterClosed().subscribe(async shouldMainMove => {
          if (shouldMainMove) {
            moveRadio();
          }
        });
      } else {
        moveRadio();
      }
    }
  }

  // Set URL  for either selected or uploaded file
  async setFileUrl(event, formGroup: FormGroup) {
    if (event && event.target.files && event.target.files[0]) {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(event.target.files[0]);

      fileReader.onload = () => {
        formGroup.controls.sPreviewImageSrc.setValue(fileReader.result);
      };
    } else {
      formGroup.controls.sPreviewImageSrc.setValue(formGroup.controls.sImagePath.value ?
        await this.s3Service.getFileUrl(formGroup.controls.sImagePath.value) : '');
    }
  }

  /**
   * Chip methods
   */

  addChip(form: FormControl, validationForm: FormControl, event: MatChipInputEvent): void {
    if (validationForm.valid) {
      const value = (event.value || '').trim();
      if (value && !form.value.includes(value)) {
        form.value.push(value);
      }
      // reset input
      if (event.input) {
        event.input.value = '';
      }
    }
  }

  removeChip(form: FormControl, synonym: string): void {
    const index = form.value.indexOf(synonym);

    if (index >= 0) {
      form.value.splice(index, 1);
    }
  }

  /**
   * Other methods
   */

  getErrorMessage(form, type) {
    return ErrorMessages[type](this.formGroup.get(form));
  }

  getErrorMessageDirect(form, type) {
    return ErrorMessages[type](form);
  }

  async checkIfAdmin() {
    if (this.formGroup && this.formGroup.controls.sCatalogId) {
      const user = await Auth.currentAuthenticatedUser();

      if (user.signInUserSession.accessToken.payload['cognito:groups']
        && user.signInUserSession.accessToken.payload['cognito:groups'].includes('Admin')) {
        this.formGroup.controls.sCatalogId.enable();
      } else {
        this.formGroup.controls.sCatalogId.disable();
      }
    }
  }

  // Update Radio Content
  updateRadioContent() {
    try {
      // console.log('Values', this.formGroup.value);
      // return;
      this.busy = new Promise(async (resolve) => {
        const oData = this.formGroup.value;

        // upload data
        const mFilesToUpload = new Map();

        oData.aRadio.forEach((radio, index) => {
          if (radio.oImageInput && radio.oImageInput !== null) {
            mFilesToUpload.set(
              `radioImage-${index}`,
              {
                type: 'image',
                input: radio.oImageInput,
                onDone: (path) => {
                  radio.sImagePath = path;
                  delete radio.oImageInput;
                }
              });
          } else {
            delete radio.oImageInput;
          }
          delete radio.sSynonym;
          delete radio.sPreviewImageSrc;
        });

        const aInputs = [
          'sLaunchInitialAudio',
          'sLaunchInitialRepromptAudio',
          'sLaunchExtendedAudio',
          'sLaunchShortAudio',
          'sHelpAudio',
          'sHelpExtendedAudio',
          'sHelpRepromptAudio',
          'sContinueAudio',
          'sStopAudio',
          'sNoMoreStreamsAudio',
          'sContinueExtendedAudio',
          'sFallbackAudio',
          'sNotSupportedAudio',
          'sErrorAudio',
          'sUnknownStreamAudio'
        ];

        aInputs.forEach(sInput => {
          if (oData[`${sInput}Input`] && oData[`${sInput}Input`] !== null) {
            mFilesToUpload.set(
              sInput,
              {
                type: 'audio',
                input: oData[sInput + 'Input'],
                onDone: (path) => {
                  oData[`${sInput}Path`] = path;
                  delete oData[`${sInput}Input`];
                }
              });
          } else {
            delete oData[sInput + 'Input'];
          }
        });

        if (mFilesToUpload.size > 0) {
          mFilesToUpload.forEach(async (data, name) => {
            this.busymessage = `Lade "${data.input.fileNames}" hoch `;
            const sKey = await this.s3Service.uploadFile(data.input.files[0], `${this.oSkill.sAlexaSkillName}/${data.input.fileNames}`);
            if (data.type === 'audio') {
              this.busymessage = `Konvertiere "${data.input.fileNames}"`;
              await this.appsyncService.MP3Conversion(this.oSkill.sAlexaSkillName, data.input.fileNames);
            }

            data.onDone(sKey);

            mFilesToUpload.delete(name);

            if (mFilesToUpload.size === 0) {
              console.log('All Files uploaded');
              await this._update(oData);
            }
          });
        } else {
          await this._update(oData);
        }
      });
    } catch (error) {
      this.snackBar.open('Es konnte nicht erfolgreich gespeichert werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

  async _update(input: UpdateRadioContentInput) {
    console.log('Data', input);
    const originalRadioContent = await this.appsyncService.getRadioContent(this.sCurrentId);
    if (input.sCatalogId && JSON.stringify({ value: input.aRadio }) !== JSON.stringify({ value: originalRadioContent.aRadio })) {
      this.busymessage = 'Erstelle neuen Katalog...';
      const radioType = { values: [] };

      input.aRadio.forEach((radio, index) => {
        radioType.values.push({
          id: index,
          name: {
            value: radio.sName,
            synonyms: radio.aSynonyms ? radio.aSynonyms : []
          }
        });
      });

      const key = await this.s3Service.uploadJson(radioType, `${this.oSkill.sAlexaSkillName}/radio-slot-type.json`);
      const url = await this.s3Service.getFileUrl(key);

      console.log('JSON uploaded', key);

      this.busymessage = 'Erstelle neue Katalog Version...';
      try {
        await this.appsyncService.createCatalogVersion(input.sCatalogId, url);
      } catch (error) {
        this.snackBar.open('Es konnte keine neue Katalog Version erstellt werden', 'Schließen', {
          duration: 5000,
          horizontalPosition: 'start',
          verticalPosition: 'bottom'
        });
      }
    }

    await this.appsyncService.updateRadioContent(input);
    this.router.navigate(['/reload'], { queryParams: { path: `/skill/radio/${input.id}` } });
  }

  // Init the skill
  async initEmptyData() {
    try {
      this.oRadioContent = await this.appsyncService.emptyNewRadioContent();
      this.oSkill = await this.appsyncService.emptyNewSkill();
      this.initGeneral(this.oRadioContent);
    } catch (oError) { }
  }

  // Get the podcast with ID from URL
  async getData() {
    try {
      this.oRadioContent = await this.appsyncService.getRadioContent(this.sCurrentId);
      this.oSkill = await this.appsyncService.getSkill(this.sCurrentId);
      this.initGeneral(this.oRadioContent);
    } catch (oError) {
      this.snackBar.open('Radio konnte nicht geladen werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

  clearFile(sFormControl) {
    this.formGroup.get(sFormControl).reset();
  }
}

import {
    getFileType
} from './Toolkit';

// Validation for Email
export function checkEmail(oControl) {
    const enteredEmail = oControl.value;
    // tslint:disable-next-line: max-line-length
    const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (!emailCheck.test(enteredEmail) && enteredEmail) ? { noEmail: true } : null;
}

// Validation for Google project ID
export function checkGoogleProjectId(oControl) {
    const enteredId = oControl.value;
    const idCheck = /^[a-z]+-[a-z]+$/;
    return (!idCheck.test(enteredId) && enteredId) ? { noProjectId: true } : null;
}

// Validation for Skill ID
export function checkAlexaSkillId(oControl) {
    const enteredId = oControl.value;
    const idCheck = /^amzn1.ask.skill.[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
    return (!idCheck.test(enteredId) && enteredId) ? { noSkillId: true } : null;
}

// Validation for Skill ID
export function checkAlexaCatalogId(oControl) {
    const enteredId = oControl.value;
    const idCheck = /^amzn1.ask.interactionModel.catalog.[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
    return (!idCheck.test(enteredId) && enteredId) ? { noCatalogId: true } : null;
}

// Validation for PNG filetype
export function checkLogoType(oControl) {
    if (oControl.value) {
        const sFileType = getFileType(oControl.value.fileNames);
        return (sFileType === 'png' || sFileType === 'PNG') ? null : { wrongFileType: true };
    } else {
        return null;
    }
}

// Validation for URL
export function checkUrl(oControl) {
    const enteredUrl = oControl.value;
    // tslint:disable-next-line: max-line-length
    const urlCheck = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return (!urlCheck.test(enteredUrl) && enteredUrl) ? { noUrl: true } : null;
}

// Validation for MP3 filetype
export function checkAudioType(oControl) {
    if (oControl.value) {
        const sFileType = getFileType(oControl.value.fileNames);
        return sFileType === 'mp3' ? null : { wrongFileType: true };
    } else {
        return null;
    }
}

// Validation for Alexa synonyms (max 140 characters; no numbers or special characters)
export function checkAlexaSynonym(oControl) {
    const synonym = oControl.value;
    if (synonym.length > 140) {
        return { tooLong: true };
    } else {
        // tslint:disable-next-line: max-line-length
        const regex = /^[a-zA-Z ]+$/;
        return (!regex.test(synonym) && synonym) ? { invalidCharacter: true } : null;
    }
}


export class PodcastActionContentType {
    constructor(
        public sOwner?: string,
        public id?: string,
        public sGoogleProjectId?: string,
        public sPodcastRssUrl?: string,
        public sContactEmail?: string,
        public bNeedEpisodePrefix?: boolean,
        public sFirstLaunchAudioPath?: string,
        public sLaunchAudioPath?: string,
        public sLaunchRepromptAudioPath?: string,
        public sStopAudioPath?: string,
        public sFallbackAudioPath?: string,
        public sEndOfPlaylistAudioPath?: string,
        public sEndOfPlaylistExtendedAudioPath?: string,
        public sNotificationPermissionConfirmedAudioPath?: string,
        public sNotificationPermissionNotPossibleAudioPath?: string,
        public sMessageRecordingAudioPath?: string,
        public sMessageReceivedAudioPath?: string,
        public sAskForInitialGuidingAudioPath?: string,
        public sInitialGuidingAudioPath?: string,
        public sNoInitialGuidingAudioPath?: string,
        public sAskForMoreActionsHelpAudioPath?: string,
        public sEpisodeIntroAudioPath?: string,
        public sEpisodeListPrefixAudioPath?: string,
        public sEpisodeSearchListPrefixAudioPath?: string,
        public sEpisodeNotFoundAudioPath?: string,
        public createdAt?: string,
        public updatedAt?: string
    ) { }
}

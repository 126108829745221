import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Auth } from 'aws-amplify';
import { PodcastActionContentType } from '../shared/PodcastActionContentType';
import { ActionType } from '../shared/ActionType';
import {
  checkEmail,
  checkGoogleProjectId,
  checkLogoType
} from '../shared/Validations';
import * as ErrorMessages from '../shared/ErrorMessages';
import * as Toolkit from '../shared/Toolkit';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppsyncService } from '../services/appsync.service';
import { S3Service } from '../services/s3.service';

@Component({
  selector: 'app-vcms-action',
  templateUrl: './vcms-action.component.html',
  styleUrls: ['./vcms-action.component.scss']
})
export class VcmsActionComponent implements OnInit {
  formGroup: FormGroup;

  bCreateMode: boolean;
  sCurrentId: string;
  sLogoLargeUrl: string;
  sLogoSmallUrl: string;
  busy: Promise<any>;
  busymessage: string;
  oAction: ActionType = {};
  oPodcastActionContent: PodcastActionContentType = {};

  getFilePath = Toolkit.getFilePath;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appsyncService: AppsyncService,
    private s3Service: S3Service,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.initEmptyData();
    this.route.params.subscribe((params = {}) => {
      if (params.id === undefined) {
        this.bCreateMode = true;
        this.initNewData();
      } else {
        this.bCreateMode = false;

        this.sCurrentId = params.id;
        this.getData();
      }
    });
  }

  ngOnInit() {
    this.busymessage = 'Speichern...';
  }



  /**
   * Initialization of variables
   */

  async initGeneral(oAction) {
    try {
      let aOwnerForm;
      if (this.bCreateMode) {
        aOwnerForm = [null, [Validators.required, checkEmail]];
      } else {
        const oUser = await Auth.currentAuthenticatedUser();
        // tslint:disable-next-line: max-line-length
        aOwnerForm = (oUser.signInUserSession.idToken.payload['cognito:groups'] && oUser.signInUserSession.idToken.payload['cognito:groups'].includes('Admin')) ?
          [oAction.sOwner, [Validators.required, checkEmail]] : [{ value: oAction.sOwner, disabled: true }];
      }

      let aGoogleActionNameForm;
      if (this.bCreateMode) {
        aGoogleActionNameForm = [oAction.sGoogleActionName, [Validators.required], this.checkIfGoogleActionNameUsed.bind(this)];
      } else {
        aGoogleActionNameForm = [{ value: oAction.sGoogleActionName, disabled: true }];
      }

      this.formGroup = this.formBuilder.group({
        id: [oAction.id],
        sGoogleActionName: aGoogleActionNameForm,
        sInvocationName: [oAction.sInvocationName, [Validators.required]],
        sGoogleProjectId: [oAction.sGoogleProjectId, [Validators.required, checkGoogleProjectId]],
        sActionType: [{ value: oAction.sActionType, disabled: true }],
        sLogoLargePath: [oAction.sLogoLargePath],
        oLogoLargeInput: this.bCreateMode ?
          [null, [Validators.required, checkLogoType.bind(this)]] :
          [null, [checkLogoType.bind(this)]],
        sLogoSmallPath: [oAction.sLogoSmallPath],
        oLogoSmallInput: this.bCreateMode ?
          [null, [Validators.required, checkLogoType.bind(this)]] :
          [null, [checkLogoType.bind(this)]],
        sOwner: aOwnerForm
      });

      this.setFileUrl(null, 'large');
      this.setFileUrl(null, 'small');
    } catch (oError) { }
  }


  /**
   * Special Validations for formfields
   */

  // Validation for Podcast Title
  checkIfGoogleActionNameUsed(oControl) {
    return new Observable(observer => {
      this.appsyncService.isGoogleActionNameUsed(oControl.value)
        .then((bAGoogleActionNameUsed) => {
          observer.next(bAGoogleActionNameUsed ? { alreadyUsed: true } : null);
          observer.complete();
        })
        .catch((oError) => {
          observer.next({ error: true });
          observer.complete();
        });
    });
  }


  /**
   * Other methods
   */

  getErrorMessage(form, type) {
    return ErrorMessages[type](this.formGroup.get(form));
  }

  // Set URL  for either selected or uploaded file
  async setFileUrl(oEvent, sSize) {
    if (oEvent && oEvent.target.files && oEvent.target.files[0]) {
      const oReader = new FileReader();

      oReader.readAsDataURL(oEvent.target.files[0]);

      oReader.onload = (oObject) => {
        sSize === 'large' ?
          // @ts-ignore
          this.sLogoLargeUrl = oObject.target.result :
          // @ts-ignore
          this.sLogoSmallUrl = oObject.target.result;
      };
    } else {
      sSize === 'large' ?
        this.formGroup.controls.sLogoLargePath.value ?
          this.sLogoLargeUrl = await this.s3Service.getFileUrl(this.formGroup.controls.sLogoLargePath.value) :
          this.sLogoLargeUrl = '' :
        this.formGroup.controls.sLogoSmallPath.value ?
          this.sLogoSmallUrl = await this.s3Service.getFileUrl(this.formGroup.controls.sLogoSmallPath.value) :
          this.sLogoSmallUrl = '';
    }
  }

  // Map value of button to variable (checked or not checked)
  // changeDeselectButton(oEvent, sButton) {
  //   this[sButton] = oEvent.checked;
  // }

  // Either create or update Action
  submitAction() {
    try {
      this.busy = new Promise(async (resolve) => {
        if (this.bCreateMode) {
          await this.createActionAndPodcast(this.formGroup.getRawValue());
        } else {
          await this.updateActionAndPodcast(this.formGroup.getRawValue());
        }
      });
    } catch (error) {
      this.snackBar.open('Es konnte nicht erfolgreich gespeichert werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

  // Upload all files and create Podcast
  async createActionAndPodcast(oData) {
    console.log('Data', oData);
    const mFilesToUpload = new Map();

    mFilesToUpload.set('LogoLarge', oData.oLogoLargeInput);
    mFilesToUpload.set('LogoSmall', oData.oLogoSmallInput);

    mFilesToUpload.forEach(async (oInput, sFileName) => {
      this.busymessage = `Uploading ${oInput.fileNames}`;
      const sKey = await this.s3Service.uploadFile(oInput.files[0], `${oData.sGoogleActionName}/action/${oInput.fileNames}`);

      delete oData[`o${sFileName}Input`];
      oData[`s${sFileName}Path`] = sKey;

      mFilesToUpload.delete(sFileName);

      if (mFilesToUpload.size === 0) {
        console.log('All Files uploaded');

        const oAction = await this.appsyncService.createAction(oData);

        this.oPodcastActionContent.id = oAction.id;
        this.oPodcastActionContent.sGoogleProjectId = oAction.sGoogleProjectId;
        this.oPodcastActionContent.sOwner = oAction.sOwner;
        this.oPodcastActionContent.bNeedEpisodePrefix = true;

        // @ts-ignore
        const oPodcastActionContent = await this.appsyncService.createPodcastActionContent(this.oPodcastActionContent);

        this.router.navigateByUrl(`/action/podcast/${oPodcastActionContent.id}`);
      }
    });
  }

  // Upload all new files and update Podcast
  async updateActionAndPodcast(oData) {
    console.log('Data', oData);
    const mFilesToUpload = new Map();

    if (oData.oLogoLargeInput && oData.oLogoLargeInput !== null) {
      mFilesToUpload.set('LogoLarge', oData.oLogoLargeInput);
    } else {
      delete oData.oLogoLargeInput;
    }

    if (oData.oLogoSmallInput && oData.oLogoSmallInput !== null) {
      mFilesToUpload.set('LogoSmall', oData.oLogoSmallInput);
    } else {
      delete oData.oLogoSmallInput;
    }

    const fUpdate = async oInput => {
      await this.appsyncService.updateAction(oInput);

      this.oPodcastActionContent.id = oInput.id;
      this.oPodcastActionContent.sGoogleProjectId = oInput.sGoogleProjectId;
      this.oPodcastActionContent.sOwner = oInput.sOwner;
      delete this.oPodcastActionContent.createdAt;
      delete this.oPodcastActionContent.updatedAt;

      // @ts-ignore
      await this.appsyncService.updatePodcastActionContent(this.oPodcastActionContent);

      this.router.navigate(['/reload'], { queryParams: { path: `/action/${oInput.id}` } });
    };

    if (mFilesToUpload.size > 0) {
      mFilesToUpload.forEach(async (oInput, sFileName) => {
        this.busymessage = `Uploading ${oInput.fileNames}`;
        const sKey = await this.s3Service.uploadFile(oInput.files[0], `${oData.sGoogleActionName}/action/${oInput.fileNames}`);

        delete oData[`o${sFileName}Input`];
        oData[`s${sFileName}Path`] = sKey;

        mFilesToUpload.delete(sFileName);

        if (mFilesToUpload.size === 0) {
          console.log('All Files uploaded');

          await fUpdate(oData);
        }
      });
    } else {
      await fUpdate(oData);
    }
  }

  // Init the action
  initEmptyData() {
    try {
      this.oPodcastActionContent = this.appsyncService.emptyNewPodcastActionContent();
      this.oAction = this.appsyncService.emptyNewAction();
      this.initGeneral(this.oAction);
    } catch (oError) { }
  }

  initNewData() {
    try {
      this.oPodcastActionContent = this.appsyncService.initNewPodcastActionContent(this.oPodcastActionContent);
      this.oAction = this.appsyncService.initNewAction(this.oAction);
      this.initGeneral(this.oAction);
    } catch (oError) { }
  }

  // Get the action with ID from URL
  async getData() {
    try {
      this.oPodcastActionContent = await this.appsyncService.getPodcastActionContent(this.sCurrentId);
      this.oAction = await this.appsyncService.getAction(this.sCurrentId);
      this.initGeneral(this.oAction);
    } catch (oError) {
      this.snackBar.open('Action konnte nicht geladen werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

}

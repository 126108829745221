export class RadioContentType {
    constructor(
        public sOwner?: string,
        public id?: string,
        public sCatalogId?: string,
        public sAlexaSkillId?: string,
        public aRadio?: RadioEntryType[],
        public sLaunchInitialAudioPath?: string,
        public sLaunchInitialRepromptAudioPath?: string,
        public sLaunchExtendedAudioPath?: string,
        public sLaunchShortAudioPath?: string,
        public sHelpAudioPath?: string,
        public sHelpExtendedAudioPath?: string,
        public sHelpRepromptAudioPath?: string,
        public sContinueAudioPath?: string,
        public sStopAudioPath?: string,
        public createdAt?: string,
        public updatedAt?: string
    ) { }
}

export class RadioEntryType {
    constructor(
        public sName?: string,
        public sUrl?: string,
        public aSynonyms?: string[]
    ) { }
}

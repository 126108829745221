export class PodcastContentType {
    constructor(
        public sOwner?: string,
        public id?: string,
        public sAlexaSkillId?: string,
        public sPodcastRssUrl?: string,
        public sContactEmail?: string,
        public bNeedEpisodePrefix?: boolean,
        public sFirstLaunchStartText?: string,
        public sFirstLaunchAudioPath?: string,
        public sLaunchStartText?: string,
        public sLaunchStartAudioPath?: string,
        public sLaunchStartRepromtText?: string,
        public sLaunchStartRepromtAudioPath?: string,
        public sLaunchResumeText?: string,
        public sLaunchResumeAudioPath?: string,
        public sLaunchResumeRepromtText?: string,
        public sLaunchResumeRepromtAudioPath?: string,
        public sStopText?: string,
        public sStopAudioPath?: string,
        public sEndOfPlaylistAudioPath?: string,
        public sEndOfPlaylistExtendedAudioPath?: string,
        public sAskForNotificationPermissionAudioPath?: string,
        public sNotificationPermissionConfirmedAudioPath?: string,
        public sMessageReceivedAudioPath?: string,
        public sAskForInitialGuidingAudioPath?: string,
        public sInitialGuidingAudioPath?: string,
        public sNoInitialGuidingAudioPath?: string,
        public sAskForMoreActionsHelpAudioPath?: string,
        public sEpisodeListPrefixAudioPath?: string,
        public sEpisodeSearchListPrefixAudioPath?: string,
        public sAskForEpisodeListAudioPath?: string,
        public createdAt?: string,
        public updatedAt?: string
    ) { }
}

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VcmsDialogPopupComponent } from '../vcms-dialog-popup/vcms-dialog-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';

import { } from '../../API';
import { AppsyncService } from '../services/appsync.service';
import { S3Service } from '../services/s3.service';

/**
 * List Component
 */

@Component({
  selector: 'app-vcms-skill-list',
  templateUrl: './vcms-skill-list.component.html',
  styleUrls: ['./vcms-skill-list.component.scss']
})
export class VcmsSkillListComponent implements OnInit {
  @Output()
  loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  private aColumnsToDisplay = ['image', 'name', 'buttons'];

  aItems = [];
  dataSource = new MatTableDataSource<any>([]);

  constructor(
    private appsyncService: AppsyncService,
    private s3Service: S3Service,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getItemList();
  }

  // Get all Podcasts (up to 1000) of logged in user (or all for admin)
  async getItemList(): Promise<any> {
    try {
      this.aItems = [];

      const aSkills = await this.appsyncService.listSkills();
      for (const oSkill of aSkills) {
        oSkill.platform = 'skill';
        this.aItems.push(oSkill);
      }

      const aActions = await this.appsyncService.listActions();
      for (const oAction of aActions) {
        oAction.platform = 'action';
        this.aItems.push(oAction);
      }

      this.aItems.sort((oItemA, oItemB) => {
        const sNameA = oItemA.sAlexaSkillName ? oItemA.sAlexaSkillName.toLowerCase() : oItemA.sGoogleActionName.toLowerCase();
        const sNameB = oItemB.sAlexaSkillName ? oItemB.sAlexaSkillName.toLowerCase() : oItemB.sGoogleActionName.toLowerCase();
        return sNameA < sNameB ? -1 : 1;
      });

      this.refreshTable();
      this.setLogoUrls();
    } catch (oError) { }
  }

  // Delete Podcast from Dynamo
  deleteEntry(id: string, name: string, platform: string, type: string) {
    try {
      const dialogRef = this.dialog.open(VcmsDialogPopupComponent, {
        width: '400px',
        data: {
          content: `
          <p>Willst du ${platform === 'skill' ? 'den Skill' : 'die Action'} <b>${name}</b> wirklich löschen?</p>
          <br>
          <p><b>Dieser Schritt kann nicht rückgängig gemacht werden!</b></p>
        `,
          yesText: 'Ja, wirklich löschen',
          noText: 'Nein'
        }
      });

      dialogRef.afterClosed().subscribe(async bDelete => {
        if (bDelete) {
          try {
            if (platform === 'skill') {
              await this.appsyncService.deleteSkill(id);
              if (type === 'Podcast') {
                await this.appsyncService.deletePodcastContent(id);
              } else {
                await this.appsyncService.deleteRadioContent(id);
              }
            } else {
              await this.appsyncService.deleteAction(id);
              await this.appsyncService.deletePodcastActionContent(id);
            }
            this.getItemList();
          } catch { }
        } else {
          console.log('No Delete');
        }
      });
    } catch (error) {
      this.snackBar.open('Der Eintrag konnte nicht gelöscht werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

  // Refresh table (else nothing would be displayed after requesting Podcast-List)
  refreshTable() {
    this.dataSource = new MatTableDataSource<any>(this.aItems);
  }

  // Get and set all Logo-URLs
  setLogoUrls() {
    this.aItems.forEach(async oItem => {
      try {
        oItem.sLogoUrl = await this.s3Service.getFileUrl(oItem.sLogoSmallPath);
      } catch (oError) { }
    });
  }
}

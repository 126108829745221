import { Injectable } from '@angular/core';
import { Storage } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor() { }

  async uploadFile(oFile, sPath): Promise<any> {
    try {
      const sKey = (await Storage.put(sPath, oFile, {
        level: 'public',
        contentType: 'text/plain',
        progressCallback(oProgress) {
          console.log(`File upload: ${oProgress.loaded}/${oProgress.total}`);
        }
        // @ts-ignore
      })).key;

      console.log('Success Upload uploadFile', sKey);
      return sKey;
    } catch (oError) {
      console.log('Error Upload uploadFile', oError);
      throw (oError);
    }
  }

  async uploadJson(json: any, path: string): Promise<any> {
    try {
      const sKey = (await Storage.put(path, JSON.stringify(json), {
        level: 'public',
        contentType: 'application/json'
        // @ts-ignore
      })).key;

      console.log('Success Upload uploadFile', sKey);
      return sKey;
    } catch (oError) {
      console.log('Error Upload uploadFile', oError);
      throw (oError);
    }
  }

  async getFileUrl(sPath): Promise<any> {
    try {
      const sUrl = (await Storage.get(sPath, {
        level: 'public'
      }));

      console.log('Success Request getFileUrl', sUrl);
      return sUrl;
    } catch (oError) {
      console.log('Error Request getFileUrl', oError);
      throw (oError);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Auth } from 'aws-amplify';

import { AppsyncService } from '../services/appsync.service';
import { S3Service } from '../services/s3.service';

@Component({
  selector: 'app-podcast-create',
  templateUrl: './podcast-create.component.html',
  styleUrls: ['./podcast-create.component.scss']
})
export class PodcastCreateComponent implements OnInit {
  formGroup: FormGroup;

  bCreateMode: boolean;
  sCurrentId: string;
  sLogoLargeUrl: string;
  sLogoSmallUrl: string;
  sBackgroundImageLargeUrl: string;
  sBackgroundImageSmallUrl: string;
  sCurrentPodcastTitle: string;
  // disableTest;
  busy: Promise<any>;
  busymessage: string;
  oPodcast = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appsyncService: AppsyncService,
    private s3Service: S3Service,
    private formBuilder: FormBuilder
  ) {

    this.initEmptyPodcast();
    this.route.params.subscribe((params = {}) => {
      if (params.id === undefined) {
        this.bCreateMode = true;
        this.initNewPodcast();
      } else {
        this.bCreateMode = false;

        this.sCurrentId = params.id;  // sv: refactore code => no difference in the if
        this.getPodcast();
        // if (!this.sCurrentId) {
        //   this.sCurrentId = params.id;
        //   this.getPodcast();
        // } else if (this.sCurrentId !== params.id) {
        //   this.sCurrentId = params.id;
        //   this.getPodcast();
        // }
      }
    });
  }

  ngOnInit() {
    this.busymessage = 'Saving...';
  }

  /**
   * Initialization of variables
   */

  async initGeneral(oPodcast) {
    try {
      let aOwnerForm;
      if (oPodcast.owner === undefined || oPodcast.owner === '') {
        aOwnerForm = [null, [Validators.required, this.checkEmail]];
      } else {
        const oUser = await Auth.currentAuthenticatedUser();
        // tslint:disable-next-line: max-line-length
        aOwnerForm = (oUser.signInUserSession.idToken.payload['cognito:groups'] && oUser.signInUserSession.idToken.payload['cognito:groups'].includes('Admin')) ?
          [oPodcast.owner, [Validators.required, this.checkEmail]] : [{ value: oPodcast.owner, disabled: true }];
      }

      this.formGroup = this.formBuilder.group({
        id: [oPodcast.id],
        podcastTitle: [oPodcast.podcastTitle, [Validators.required]],
        invocationName: [oPodcast.invocationName, [Validators.required]],
        alexaSkillId: [oPodcast.alexaSkillId, [Validators.required, this.checkAlexaSkillId]],
        podcastRssUrl: [oPodcast.podcastRssUrl, [Validators.required, this.checkUrl]],
        email: [oPodcast.email, [Validators.required, this.checkEmail]],
        startPlayInitText: [oPodcast.startPlayInitText],
        startPlayInitAudioPath: [oPodcast.startPlayInitAudioPath],
        startPlayInitAudioInput: [null, [this.checkAudioType.bind(this)]],
        startPlayNewText: [oPodcast.startPlayNewText],
        startPlayNewAudioPath: [oPodcast.startPlayNewAudioPath],
        startPlayNewAudioInput: [null, [this.checkAudioType.bind(this)]],
        startPlayRepromptText: [oPodcast.startPlayRepromptText],
        startPlayRepromptAudioPath: [oPodcast.startPlayRepromptAudioPath],
        startPlayRepromptAudioInput: [null, [this.checkAudioType.bind(this)]],
        startResumeText: [oPodcast.startResumeText],
        startResumeAudioPath: [oPodcast.startResumeAudioPath],
        startResumeAudioInput: [null, [this.checkAudioType.bind(this)]],
        startResumeRepromptText: [oPodcast.startResumeRepromptText],
        startResumeRepromptAudioPath: [oPodcast.startResumeRepromptAudioPath],
        startResumeRepromptAudioInput: [null, [this.checkAudioType.bind(this)]],
        finishText: [oPodcast.finishText],
        finishAudioPath: [oPodcast.finishAudioPath],
        finishAudioInput: [null, [this.checkAudioType.bind(this)]],
        endOfPlaylistAudioPath: [oPodcast.endOfPlaylistAudioPath],
        endOfPlaylistAudioInput: [null, [this.checkAudioType.bind(this)]],
        endOfPlaylistExtendedAudioPath: [oPodcast.endOfPlaylistExtendedAudioPath],
        endOfPlaylistExtendedAudioInput: [null, [this.checkAudioType.bind(this)]],
        notificationPermissionAudioPath: [oPodcast.notificationPermissionAudioPath],
        notificationPermissionAudioInput: [null, [this.checkAudioType.bind(this)]],
        notificationActivatedAudioPath: [oPodcast.notificationActivatedAudioPath],
        notificationActivatedAudioInput: [null, [this.checkAudioType.bind(this)]],
        messageReceivedAudioPath: [oPodcast.messageReceivedAudioPath],
        messageReceivedAudioInput: [null, [this.checkAudioType.bind(this)]],
        askForGuidingAudioPath: [oPodcast.askForGuidingAudioPath],
        askForGuidingAudioInput: [null, [this.checkAudioType.bind(this)]],
        guidingAudioPath: [oPodcast.guidingAudioPath],
        guidingAudioInput: [null, [this.checkAudioType.bind(this)]],
        noGuidingAudioPath: [oPodcast.noGuidingAudioPath],
        noGuidingAudioInput: [null, [this.checkAudioType.bind(this)]],
        askForMoreActionsAudioPath: [oPodcast.askForMoreActionsAudioPath],
        askForMoreActionsAudioInput: [null, [this.checkAudioType.bind(this)]],
        logoLargePath: [oPodcast.logoLargePath],
        logoLargeInput: [null, [this.checkLogoType.bind(this)]],
        logoSmallPath: [oPodcast.logoSmallPath],
        logoSmallInput: [null, [this.checkLogoType.bind(this)]],
        backgroundImageLargePath: [oPodcast.backgroundImageLargePath],
        backgroundImageLargeInput: [null],
        backgroundImageSmallPath: [oPodcast.backgroundImageSmallPath],
        backgroundImageSmallInput: [null],
        owner: aOwnerForm
      });

      this.setFileUrl(null, 'logo', 'large');
      this.setFileUrl(null, 'logo', 'small');
      this.setFileUrl(null, 'background', 'large');
      this.setFileUrl(null, 'background', 'small');
      this.sCurrentPodcastTitle = oPodcast.podcastTitle;

    } catch (oError) { }
  }

  clearFile(oEvent, sFormControl) {
    this.formGroup.get(sFormControl).reset();
  }


  /**
   * Validations for formfields
   */

  // Validation for Podcast Title
  checkUsedPodcastTitle(oControl) {
    console.log(oControl.value);
    return new Observable(observer => {
      this.appsyncService.podcastTitleUsed(oControl.value)
        .then((bPodcastTitleUsed) => {
          observer.next(bPodcastTitleUsed ? { alreadyUsed: true } : null);
          observer.complete();
        })
        .catch((oError) => {
          observer.next({ errorUsed: true });
          observer.complete();
        });
    });
  }

  // Validation for Skill ID
  checkAlexaSkillId(oControl) {
    const enteredId = oControl.value;
    const idCheck = /^amzn1.ask.skill.[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
    return (!idCheck.test(enteredId) && enteredId) ? { noSkillId: true } : null;
  }

  // Validation for URL
  checkUrl(oControl) {
    const enteredUrl = oControl.value;
    // tslint:disable-next-line: max-line-length
    const urlCheck = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return (!urlCheck.test(enteredUrl) && enteredUrl) ? { noUrl: true } : null;
  }

  // Validation for Email
  checkEmail(oControl) {
    const enteredEmail = oControl.value;
    // tslint:disable-next-line: max-line-length
    const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (!emailCheck.test(enteredEmail) && enteredEmail) ? { noEmail: true } : null;
  }

  // Validation for MP3 filetype
  checkAudioType(oControl) {
    if (oControl.value) {
      const sFileType = this.getFileType(oControl.value.fileNames);
      return sFileType === 'mp3' ? null : { wrongFileType: true };
    } else {
      return null;
    }
  }

  // Validation for PNG filetype
  checkLogoType(oControl) {
    if (oControl.value) {
      const sFileType = this.getFileType(oControl.value.fileNames);
      return (sFileType === 'png' || sFileType === 'PNG') ? null : { wrongFileType: true };
    } else {
      return null;
    }
  }

  /**
   * Error Messages for input fields
   */

  getErrorOwner() {
    return this.formGroup.get('owner').hasError('required') ? 'Bitte wähle einen Besitzer' :
      this.formGroup.get('owner').hasError('noEmail') ? 'Bitte wähle eine valide E-Mail-Adresse' : '';
  }

  getErrorPodcastTitle() {
    return this.formGroup.get('podcastTitle').hasError('required') ? 'Field is required' :
      this.formGroup.get('podcastTitle').hasError('alreadyUsed') ? 'This podcast title is already in use' :
        this.formGroup.get('podcastTitle').hasError('errorUsed') ? 'Error while checking for podcast title' : '';
  }

  getErrorInvocationName() {
    return this.formGroup.get('invocationName').hasError('required') ? 'Field is required' : '';
  }

  getErrorAlexaSkillId() {
    return this.formGroup.get('alexaSkillId').hasError('required') ? 'Field is required' :
      // tslint:disable-next-line: max-line-length
      this.formGroup.get('alexaSkillId').hasError('noSkillId') ? 'Please enter a valid Alexa Skill ID (amzn1.ask.skill.xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)' : '';
  }

  getErrorPodcastRssUrl() {
    return this.formGroup.get('podcastRssUrl').hasError('required') ? 'Field is required' :
      this.formGroup.get('podcastRssUrl').hasError('noUrl') ? 'Please enter a valid URL' : '';
  }

  getErrorEmail() {
    return this.formGroup.get('email').hasError('required') ? 'Bitte wähle eine E-Mail-Adresse' :
      this.formGroup.get('email').hasError('noEmail') ? 'Bitte wähle eine valide E-Mail-Adresse' : '';
  }

  getErrorLogoLarge() {
    return this.formGroup.get('logoLargeInput').hasError('required') ? 'Bitte wähle ein großes Logo' :
      this.formGroup.get('logoLargeInput').hasError('wrongFileType') ? 'Bitte wähle ein Bild im PNG-Format' : '';
  }

  getErrorLogoSmall() {
    return this.formGroup.get('logoSmallInput').hasError('required') ? 'Bitte wähle ein kleines Logo' :
      this.formGroup.get('logoSmallInput').hasError('wrongFileType') ? 'Bitte wähle ein Bild im PNG-Format' : '';
  }

  getErrorBackgroundImageLarge() {
    return this.formGroup.get('backgroundImageLargeInput').hasError('required') ? 'Bitte wähle ein großes Hintergrundbild' : '';
  }

  getErrorBackgroundImageSmall() {
    return this.formGroup.get('backgroundImageSmallInput').hasError('required') ? 'Bitte wähle ein kleines Hintergrundbild' : '';
  }

  getErrorStartPlayInitAudio() {
    return this.formGroup.get('startPlayInitAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorStartPlayNewAudio() {
    return this.formGroup.get('startPlayNewAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorStartPlayRepromptAudio() {
    return this.formGroup.get('startPlayRepromptAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorStartResumeAudio() {
    return this.formGroup.get('startResumeAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorStartResumeRepromptAudio() {
    return this.formGroup.get('startResumeRepromptAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorFinishAudio() {
    return this.formGroup.get('finishAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorEndOfPlaylistAudio() {
    return this.formGroup.get('endOfPlaylistAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorEndOfPlaylistExtendedAudio() {
    // tslint:disable-next-line: max-line-length
    return this.formGroup.get('endOfPlaylistExtendedAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorNotificationPermissionAudio() {
    // tslint:disable-next-line: max-line-length
    return this.formGroup.get('notificationPermissionAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorNotificationActivatedAudio() {
    // tslint:disable-next-line: max-line-length
    return this.formGroup.get('notificationActivatedAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorMessageReceivedAudio() {
    return this.formGroup.get('messageReceivedAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorAskForGuidingAudio() {
    return this.formGroup.get('askForGuidingAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorGuidingAudio() {
    return this.formGroup.get('guidingAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorNoGuidingAudio() {
    return this.formGroup.get('noGuidingAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  getErrorAskForMoreActionsAudio() {
    return this.formGroup.get('askForMoreActionsAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  /**
   * Other methods
   */

  // Set URL  for either selected or uploaded file
  async setFileUrl(oEvent, sGroup, sSize) {
    if (oEvent && oEvent.target.files && oEvent.target.files[0]) {
      const oReader = new FileReader();

      oReader.readAsDataURL(oEvent.target.files[0]);

      oReader.onload = (oObject) => {
        sGroup === 'logo' ?
          sSize === 'large' ?
            // @ts-ignore
            this.sLogoLargeUrl = oObject.target.result :
            // @ts-ignore
            this.sLogoSmallUrl = oObject.target.result :
          sSize === 'large' ?
            // @ts-ignore
            this.sBackgroundImageLargeUrl = oObject.target.result :
            // @ts-ignore
            this.sBackgroundImageSmallUrl = oObject.target.result;
      };
    } else {
      sGroup === 'logo' ?
        sSize === 'large' ?
          this.formGroup.controls.logoLargePath.value ?
            this.sLogoLargeUrl = await this.s3Service.getFileUrl(this.formGroup.controls.logoLargePath.value) :
            this.sLogoLargeUrl = '' :
          this.formGroup.controls.logoSmallPath.value ?
            this.sLogoSmallUrl = await this.s3Service.getFileUrl(this.formGroup.controls.logoSmallPath.value) :
            this.sLogoSmallUrl = '' :
        sSize === 'large' ?
          this.formGroup.controls.backgroundImageLargePath.value ?
            this.sBackgroundImageLargeUrl = await this.s3Service.getFileUrl(this.formGroup.controls.backgroundImageLargePath.value) :
            this.sBackgroundImageLargeUrl = '' :
          this.formGroup.controls.backgroundImageSmallPath.value ?
            this.sBackgroundImageSmallUrl = await this.s3Service.getFileUrl(this.formGroup.controls.backgroundImageSmallPath.value) :
            this.sBackgroundImageSmallUrl = '';
    }
  }

  // Map value of button to variable (checked or not checked)
  changeDeselectButton(oEvent, sButton) {
    this[sButton] = oEvent.checked;
  }

  // Get Path of uploaded file (without folder of S3 bucket)
  getFilePath(sPath) {
    const re = /\/(.+)$/;
    return sPath === null || sPath === undefined ? null : re.exec(sPath)[1];
  }

  // Get type of file
  getFileType(sFileName) {
    const re = /(?:\.([^.]+))?$/;
    return sFileName === null || sFileName === undefined ? null : re.exec(sFileName)[1];
  }

  // Either create or update Podcast
  submitPodcast() {
    // const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
    // this.busy = wait(100000);
    this.busy = new Promise(async (resolve) => {
      if (this.bCreateMode) {
        await this.createPodcast(this.formGroup.value);
      } else {
        await this.updatePodcast(this.formGroup.value);
      }
    });
    // this.bCreateMode ? this.createPodcast(this.formGroup.value) : this.updatePodcast(this.formGroup.value);
  }

  async convertMP3s(sTitle, mFilesToUpload) {
    console.log(mFilesToUpload);

    // Hack: Needed because await is not working within a foreach
    const mFilesToUploadArray = [];
    mFilesToUpload.forEach(element => {
      mFilesToUploadArray.push(element.fileNames);
    });


    for (const fileNames of mFilesToUploadArray) {
      console.log(fileNames);
      await this.appsyncService.MP3Conversion(sTitle, fileNames);
      console.log('conversion one finished');
    }

    console.log('conversion all finished');
  }


  // Upload all files and create Podcast
  async createPodcast(oData) {
    console.log('Data', oData);
    const mFilesToUpload = new Map();

    if (oData.startPlayInitAudioInput && oData.startPlayInitAudioInput !== null) {
      mFilesToUpload.set('startPlayInitAudio', oData.startPlayInitAudioInput);
    } else {
      delete oData.startPlayInitAudioInput;
    }

    if (oData.startPlayNewAudioInput && oData.startPlayNewAudioInput !== null) {
      mFilesToUpload.set('startPlayNewAudio', oData.startPlayNewAudioInput);
    } else {
      delete oData.startPlayNewAudioInput;
    }

    if (oData.startPlayRepromptAudioInput && oData.startPlayRepromptAudioInput !== null) {
      mFilesToUpload.set('startPlayRepromptAudio', oData.startPlayRepromptAudioInput);
    } else {
      delete oData.startPlayRepromptAudioInput;
    }

    if (oData.startResumeAudioInput && oData.startResumeAudioInput !== null) {
      mFilesToUpload.set('startResumeAudio', oData.startResumeAudioInput);
    } else {
      delete oData.startResumeAudioInput;
    }

    if (oData.startResumeRepromptAudioInput && oData.startResumeRepromptAudioInput !== null) {
      mFilesToUpload.set('startResumeRepromptAudio', oData.startResumeRepromptAudioInput);
    } else {
      delete oData.startResumeRepromptAudioInput;
    }

    if (oData.finishAudioInput && oData.finishAudioInput !== null) {
      mFilesToUpload.set('finishAudio', oData.finishAudioInput);
    } else {
      delete oData.finishAudioInput;
    }

    if (oData.endOfPlaylistAudioInput && oData.endOfPlaylistAudioInput !== null) {
      mFilesToUpload.set('endOfPlaylistAudio', oData.endOfPlaylistAudioInput);
    } else {
      delete oData.endOfPlaylistAudioInput;
    }

    if (oData.endOfPlaylistExtendedAudioInput && oData.endOfPlaylistExtendedAudioInput !== null) {
      mFilesToUpload.set('endOfPlaylistExtendedAudio', oData.endOfPlaylistExtendedAudioInput);
    } else {
      delete oData.endOfPlaylistExtendedAudioInput;
    }

    if (oData.notificationPermissionAudioInput && oData.notificationPermissionAudioInput !== null) {
      mFilesToUpload.set('notificationPermissionAudio', oData.notificationPermissionAudioInput);
    } else {
      delete oData.notificationPermissionAudioInput;
    }

    if (oData.notificationActivatedAudioInput && oData.notificationActivatedAudioInput !== null) {
      mFilesToUpload.set('notificationActivatedAudio', oData.notificationActivatedAudioInput);
    } else {
      delete oData.notificationActivatedAudioInput;
    }

    if (oData.messageReceivedAudioInput && oData.messageReceivedAudioInput !== null) {
      mFilesToUpload.set('messageReceivedAudio', oData.messageReceivedAudioInput);
    } else {
      delete oData.messageReceivedAudioInput;
    }

    if (oData.askForGuidingAudioInput && oData.askForGuidingAudioInput !== null) {
      mFilesToUpload.set('askForGuidingAudio', oData.askForGuidingAudioInput);
    } else {
      delete oData.askForGuidingAudioInput;
    }

    if (oData.guidingAudioInput && oData.guidingAudioInput !== null) {
      mFilesToUpload.set('guidingAudio', oData.guidingAudioInput);
    } else {
      delete oData.guidingAudioInput;
    }

    if (oData.noGuidingAudioInput && oData.noGuidingAudioInput !== null) {
      mFilesToUpload.set('noGuidingAudio', oData.noGuidingAudioInput);
    } else {
      delete oData.noGuidingAudioInput;
    }

    if (oData.askForMoreActionsAudioInput && oData.askForMoreActionsAudioInput !== null) {
      mFilesToUpload.set('askForMoreActionsAudio', oData.askForMoreActionsAudioInput);
    } else {
      delete oData.askForMoreActionsAudioInput;
    }

    mFilesToUpload.set('backgroundImageLarge', oData.backgroundImageLargeInput);
    mFilesToUpload.set('backgroundImageSmall', oData.backgroundImageSmallInput);

    mFilesToUpload.set('logoLarge', oData.logoLargeInput);
    mFilesToUpload.set('logoSmall', oData.logoSmallInput);

    mFilesToUpload.forEach(async (oInput, sFileName) => {
      try {
        this.busymessage = `Uploading ${oInput.fileNames}`;
        const sKey = await this.s3Service.uploadFile(oInput.files[0], `${oData.podcastTitle}/${oInput.fileNames}`);
        if (this.getFileType(oInput.fileNames).toLowerCase() === 'mp3') {
          this.busymessage = `Converting ${oInput.fileNames}`;
          await this.appsyncService.MP3Conversion(oData.podcastTitle, oInput.fileNames);
        }
        delete oData[`${sFileName}Input`];
        oData[`${sFileName}Path`] = sKey;

        mFilesToUpload.delete(sFileName);

        if (mFilesToUpload.size === 0) {
          console.log('All Files uploaded');

          // await this.convertMP3s(oData.podcastTitle, mFilesToUpload);
          console.log('All Files converted');

          this.router.navigateByUrl(`/v1/podcast/${(await this.appsyncService.createPodcast(oData)).id}`);
        }
      } catch (oError) { }
    });
  }

  // Upload all new files and update Podcast
  async updatePodcast(oData) {

    console.log('Data', oData);
    oData.podcastTitle = this.sCurrentPodcastTitle;
    const mFilesToUpload = new Map();

    if (oData.startPlayInitAudioInput && oData.startPlayInitAudioInput !== null) {
      mFilesToUpload.set('startPlayInitAudio', oData.startPlayInitAudioInput);
    } else {
      delete oData.startPlayInitAudioInput;
    }

    if (oData.startPlayNewAudioInput && oData.startPlayNewAudioInput !== null) {
      mFilesToUpload.set('startPlayNewAudio', oData.startPlayNewAudioInput);
    } else {
      delete oData.startPlayNewAudioInput;
    }

    if (oData.startPlayRepromptAudioInput && oData.startPlayRepromptAudioInput !== null) {
      mFilesToUpload.set('startPlayRepromptAudio', oData.startPlayRepromptAudioInput);
    } else {
      delete oData.startPlayRepromptAudioInput;
    }

    if (oData.startResumeAudioInput && oData.startResumeAudioInput !== null) {
      mFilesToUpload.set('startResumeAudio', oData.startResumeAudioInput);
    } else {
      delete oData.startResumeAudioInput;
    }

    if (oData.startResumeRepromptAudioInput && oData.startResumeRepromptAudioInput !== null) {
      mFilesToUpload.set('startResumeRepromptAudio', oData.startResumeRepromptAudioInput);
    } else {
      delete oData.startResumeRepromptAudioInput;
    }

    if (oData.finishAudioInput && oData.finishAudioInput !== null) {
      mFilesToUpload.set('finishAudio', oData.finishAudioInput);
    } else {
      delete oData.finishAudioInput;
    }

    if (oData.endOfPlaylistAudioInput && oData.endOfPlaylistAudioInput !== null) {
      mFilesToUpload.set('endOfPlaylistAudio', oData.endOfPlaylistAudioInput);
    } else {
      delete oData.endOfPlaylistAudioInput;
    }

    if (oData.endOfPlaylistExtendedAudioInput && oData.endOfPlaylistExtendedAudioInput !== null) {
      mFilesToUpload.set('endOfPlaylistExtendedAudio', oData.endOfPlaylistExtendedAudioInput);
    } else {
      delete oData.endOfPlaylistExtendedAudioInput;
    }

    if (oData.notificationPermissionAudioInput && oData.notificationPermissionAudioInput !== null) {
      mFilesToUpload.set('notificationPermissionAudio', oData.notificationPermissionAudioInput);
    } else {
      delete oData.notificationPermissionAudioInput;
    }

    if (oData.notificationActivatedAudioInput && oData.notificationActivatedAudioInput !== null) {
      mFilesToUpload.set('notificationActivatedAudio', oData.notificationActivatedAudioInput);
    } else {
      delete oData.notificationActivatedAudioInput;
    }

    if (oData.messageReceivedAudioInput && oData.messageReceivedAudioInput !== null) {
      mFilesToUpload.set('messageReceivedAudio', oData.messageReceivedAudioInput);
    } else {
      delete oData.messageReceivedAudioInput;
    }

    if (oData.askForGuidingAudioInput && oData.askForGuidingAudioInput !== null) {
      mFilesToUpload.set('askForGuidingAudio', oData.askForGuidingAudioInput);
    } else {
      delete oData.askForGuidingAudioInput;
    }

    if (oData.guidingAudioInput && oData.guidingAudioInput !== null) {
      mFilesToUpload.set('guidingAudio', oData.guidingAudioInput);
    } else {
      delete oData.guidingAudioInput;
    }


    if (oData.noGuidingAudioInput && oData.noGuidingAudioInput !== null) {
      mFilesToUpload.set('noGuidingAudio', oData.noGuidingAudioInput);
    } else {
      delete oData.noGuidingAudioInput;
    }

    if (oData.askForMoreActionsAudioInput && oData.askForMoreActionsAudioInput !== null) {
      mFilesToUpload.set('askForMoreActionsAudio', oData.askForMoreActionsAudioInput);
    } else {
      delete oData.askForMoreActionsAudioInput;
    }

    if (oData.backgroundImageLargeInput && oData.backgroundImageLargeInput !== null) {
      mFilesToUpload.set('backgroundImageLarge', oData.backgroundImageLargeInput);
    } else {
      delete oData.backgroundImageLargeInput;
    }

    if (oData.backgroundImageSmallInput && oData.backgroundImageSmallInput !== null) {
      mFilesToUpload.set('backgroundImageSmall', oData.backgroundImageSmallInput);
    } else {
      delete oData.backgroundImageSmallInput;
    }

    if (oData.logoLargeInput && oData.logoLargeInput !== null) {
      mFilesToUpload.set('logoLarge', oData.logoLargeInput);
    } else {
      delete oData.logoLargeInput;
    }

    if (oData.logoSmallInput && oData.logoSmallInput !== null) {
      mFilesToUpload.set('logoSmall', oData.logoSmallInput);
    } else {
      delete oData.logoSmallInput;
    }

    if (mFilesToUpload.size > 0) {
      mFilesToUpload.forEach(async (oInput, sFileName) => {
        try {
          this.busymessage = `Uploading ${oInput.fileNames}`;
          const sKey = await this.s3Service.uploadFile(oInput.files[0], `${oData.podcastTitle}/${oInput.fileNames}`);
          this.busymessage = `Converting ${oInput.fileNames}`;
          await this.appsyncService.MP3Conversion(oData.podcastTitle, oInput.fileNames);

          delete oData[`${sFileName}Input`];
          oData[`${sFileName}Path`] = sKey;

          mFilesToUpload.delete(sFileName);

          if (mFilesToUpload.size === 0) {
            console.log('All Files uploaded');

            // await this.convertMP3s(oData.podcastTitle, mFilesToUpload);
            console.log('All Files converted');

            this.router.navigate(['/reload'], {
                queryParams: { path: `/v1/podcast/${(await this.appsyncService.updatePodcast(oData)).id}` }
            });
          }
        } catch (oError) { }
      });
    } else {
      try {
        this.router.navigate(['/reload'], { queryParams: { path: `/v1/podcast/${(await this.appsyncService.updatePodcast(oData)).id}` } });
      } catch (oError) { }
    }
  }

  // Init the podcast
  async initEmptyPodcast() {
    try {
      this.oPodcast = await this.appsyncService.emptyNewPodcast();
      this.initGeneral(this.oPodcast);
    } catch (oError) { }
  }

  // Init the podcast
  async initNewPodcast() {
    try {
      this.oPodcast = await this.appsyncService.initNewPodcast(this.oPodcast);
      this.initGeneral(this.oPodcast);
    } catch (oError) { }
  }

  // Get the podcast with ID from URL
  async getPodcast() {
    try {
      this.oPodcast = await this.appsyncService.getPodcast(this.sCurrentId);
      this.initGeneral(this.oPodcast);
    } catch (oError) { }
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-vcms-audio-input',
  templateUrl: './vcms-audio-input.component.html',
  styleUrls: ['./vcms-audio-input.component.scss']
})
export class VcmsAudioInputComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() textFormControlName: string;
  @Input() textDefault: string;
  @Input() textLabel: string;
  @Input() inputFormControlNamePrefix: string;
  @Input() currentPath: string;
  @Output() getErrorMessage: EventEmitter<any> = new EventEmitter();
  @Output() clearFile: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
import { PodcastListComponent } from './podcast-list/podcast-list.component';
import { PodcastCreateComponent } from './podcast-create/podcast-create.component';
import { PodcastAnalyticsComponent } from './podcast-analytics/podcast-analytics.component';
import { VcmsCalendarComponent } from './vcms-calendar/vcms-calendar.component';
import { VcmsSkillComponent } from './vcms-skill/vcms-skill.component';
import { VcmsPodcastContentComponent } from './vcms-podcast-content/vcms-podcast-content.component';
import { VcmsRadioContentComponent } from './vcms-radio-content/vcms-radio-content.component';
import { VcmsActionComponent } from './vcms-action/vcms-action.component';
import { VcmsPodcastActionContentComponent } from './vcms-podcast-action-content/vcms-podcast-action-content.component';
import { VcmsSkillListComponent } from './vcms-skill-list/vcms-skill-list.component';
import { ReloadComponentComponent } from './reload-component/reload-component.component';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
    { path: '', redirectTo: '/auth', pathMatch: 'full' },
    { path: 'auth', component: AuthComponent },
    { path: 'podcast', component: PodcastListComponent }, // alt
    { path: 'v1/podcast/create', component: PodcastCreateComponent }, // alt
    { path: 'v1/podcast/:id', component: PodcastCreateComponent }, // alt
    { path: 'overview', component: VcmsSkillListComponent },
    { path: 'skill/create', component: VcmsSkillComponent },
    { path: 'skill/:id', component: VcmsSkillComponent },
    { path: 'skill/podcast/:id', component: VcmsPodcastContentComponent },
    { path: 'skill/radio/:id', component: VcmsRadioContentComponent },
    { path: 'action/create', component: VcmsActionComponent },
    { path: 'action/:id', component: VcmsActionComponent },
    { path: 'action/podcast/:id', component: VcmsPodcastActionContentComponent },
    { path: 'dashboard', loadChildren: () => import('./vcms-dashboard/dashboard.module').then(m => m.DashboardModule) },
    { path: 'analytics/:id', component: PodcastAnalyticsComponent },
    { path: 'calendar/:id', component: VcmsCalendarComponent },
    { path: 'reload', component: ReloadComponentComponent },
    { path: 'error', component: ErrorComponent },
    { path: '**', component: ErrorComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-vcms-page-header',
    template: `
        <h1>{{ header }}</h1>
    `
})
export class PageHeaderComponent {
    @Input() header: string;
}

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const smapiCreateCatalogVersion = /* GraphQL */ `
  mutation SmapiCreateCatalogVersion($sCatalogID: ID!, $sJsonUrl: String) {
    SMAPICreateCatalogVersion(sCatalogID: $sCatalogID, sJsonUrl: $sJsonUrl)
  }
`;
export const smapiUpdateLanguageModel = /* GraphQL */ `
  mutation SmapiUpdateLanguageModel(
    $sVendorID: ID!
    $sSkillID: ID!
    $oData: String
  ) {
    SMAPIUpdateLanguageModel(
      sVendorID: $sVendorID
      sSkillID: $sSkillID
      oData: $oData
    ) {
      sStatus
    }
  }
`;
export const createPodcast = /* GraphQL */ `
  mutation CreatePodcast($input: CreatePodcastInput!) {
    createPodcast(input: $input) {
      id
      podcastTitle
      invocationName
      alexaSkillId
      podcastRssUrl
      email
      startPlayInitText
      startPlayInitAudioPath
      startPlayNewText
      startPlayNewAudioPath
      startPlayRepromptText
      startPlayRepromptAudioPath
      startResumeText
      startResumeAudioPath
      startResumeRepromptText
      startResumeRepromptAudioPath
      finishText
      finishAudioPath
      endOfPlaylistAudioPath
      endOfPlaylistExtendedAudioPath
      notificationPermissionAudioPath
      notificationActivatedAudioPath
      messageReceivedAudioPath
      askForGuidingAudioPath
      guidingAudioPath
      noGuidingAudioPath
      askForMoreActionsAudioPath
      logoLargePath
      logoSmallPath
      backgroundImageLargePath
      backgroundImageSmallPath
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updatePodcast = /* GraphQL */ `
  mutation UpdatePodcast($input: UpdatePodcastInput!) {
    updatePodcast(input: $input) {
      id
      podcastTitle
      invocationName
      alexaSkillId
      podcastRssUrl
      email
      startPlayInitText
      startPlayInitAudioPath
      startPlayNewText
      startPlayNewAudioPath
      startPlayRepromptText
      startPlayRepromptAudioPath
      startResumeText
      startResumeAudioPath
      startResumeRepromptText
      startResumeRepromptAudioPath
      finishText
      finishAudioPath
      endOfPlaylistAudioPath
      endOfPlaylistExtendedAudioPath
      notificationPermissionAudioPath
      notificationActivatedAudioPath
      messageReceivedAudioPath
      askForGuidingAudioPath
      guidingAudioPath
      noGuidingAudioPath
      askForMoreActionsAudioPath
      logoLargePath
      logoSmallPath
      backgroundImageLargePath
      backgroundImageSmallPath
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deletePodcast = /* GraphQL */ `
  mutation DeletePodcast($input: DeletePodcastInput!) {
    deletePodcast(input: $input) {
      id
      podcastTitle
      invocationName
      alexaSkillId
      podcastRssUrl
      email
      startPlayInitText
      startPlayInitAudioPath
      startPlayNewText
      startPlayNewAudioPath
      startPlayRepromptText
      startPlayRepromptAudioPath
      startResumeText
      startResumeAudioPath
      startResumeRepromptText
      startResumeRepromptAudioPath
      finishText
      finishAudioPath
      endOfPlaylistAudioPath
      endOfPlaylistExtendedAudioPath
      notificationPermissionAudioPath
      notificationActivatedAudioPath
      messageReceivedAudioPath
      askForGuidingAudioPath
      guidingAudioPath
      noGuidingAudioPath
      askForMoreActionsAudioPath
      logoLargePath
      logoSmallPath
      backgroundImageLargePath
      backgroundImageSmallPath
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createSkill = /* GraphQL */ `
  mutation CreateSkill($input: CreateSkillInput!) {
    createSkill(input: $input) {
      id
      sAlexaSkillName
      sInvocationName
      sAlexaSkillId
      sLogoLargePath
      sLogoSmallPath
      sBackgroundImageLargePath
      sBackgroundImageSmallPath
      sSkillType
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill($input: UpdateSkillInput!) {
    updateSkill(input: $input) {
      id
      sAlexaSkillName
      sInvocationName
      sAlexaSkillId
      sLogoLargePath
      sLogoSmallPath
      sBackgroundImageLargePath
      sBackgroundImageSmallPath
      sSkillType
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill($input: DeleteSkillInput!) {
    deleteSkill(input: $input) {
      id
      sAlexaSkillName
      sInvocationName
      sAlexaSkillId
      sLogoLargePath
      sLogoSmallPath
      sBackgroundImageLargePath
      sBackgroundImageSmallPath
      sSkillType
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const createPodcastContent = /* GraphQL */ `
  mutation CreatePodcastContent($input: CreatePodcastContentInput!) {
    createPodcastContent(input: $input) {
      id
      sAlexaSkillId
      sPodcastRssUrl
      sContactEmail
      bNeedEpisodePrefix
      sFirstLaunchStartText
      sFirstLaunchAudioPath
      sLaunchStartText
      sLaunchStartAudioPath
      sLaunchStartRepromtText
      sLaunchStartRepromtAudioPath
      sLaunchResumeText
      sLaunchResumeAudioPath
      sLaunchResumeRepromtText
      sLaunchResumeRepromtAudioPath
      sNewOrResumeText
      sNewOrResumeAudioPath
      sNewOrResumeRepromtText
      sNewOrResumeRepromtAudioPath
      sStopText
      sStopAudioPath
      sEndOfPlaylistAudioPath
      sEndOfPlaylistExtendedAudioPath
      sAskForNotificationPermissionAudioPath
      sNotificationPermissionConfirmedAudioPath
      sMessageReceivedAudioPath
      sAskForInitialGuidingAudioPath
      sInitialGuidingAudioPath
      sNoInitialGuidingAudioPath
      sAskForMoreActionsHelpAudioPath
      sEpisodeListPrefixAudioPath
      sEpisodeSearchListPrefixAudioPath
      sAskForEpisodeListAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const updatePodcastContent = /* GraphQL */ `
  mutation UpdatePodcastContent($input: UpdatePodcastContentInput!) {
    updatePodcastContent(input: $input) {
      id
      sAlexaSkillId
      sPodcastRssUrl
      sContactEmail
      bNeedEpisodePrefix
      sFirstLaunchStartText
      sFirstLaunchAudioPath
      sLaunchStartText
      sLaunchStartAudioPath
      sLaunchStartRepromtText
      sLaunchStartRepromtAudioPath
      sLaunchResumeText
      sLaunchResumeAudioPath
      sLaunchResumeRepromtText
      sLaunchResumeRepromtAudioPath
      sNewOrResumeText
      sNewOrResumeAudioPath
      sNewOrResumeRepromtText
      sNewOrResumeRepromtAudioPath
      sStopText
      sStopAudioPath
      sEndOfPlaylistAudioPath
      sEndOfPlaylistExtendedAudioPath
      sAskForNotificationPermissionAudioPath
      sNotificationPermissionConfirmedAudioPath
      sMessageReceivedAudioPath
      sAskForInitialGuidingAudioPath
      sInitialGuidingAudioPath
      sNoInitialGuidingAudioPath
      sAskForMoreActionsHelpAudioPath
      sEpisodeListPrefixAudioPath
      sEpisodeSearchListPrefixAudioPath
      sAskForEpisodeListAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const deletePodcastContent = /* GraphQL */ `
  mutation DeletePodcastContent($input: DeletePodcastContentInput!) {
    deletePodcastContent(input: $input) {
      id
      sAlexaSkillId
      sPodcastRssUrl
      sContactEmail
      bNeedEpisodePrefix
      sFirstLaunchStartText
      sFirstLaunchAudioPath
      sLaunchStartText
      sLaunchStartAudioPath
      sLaunchStartRepromtText
      sLaunchStartRepromtAudioPath
      sLaunchResumeText
      sLaunchResumeAudioPath
      sLaunchResumeRepromtText
      sLaunchResumeRepromtAudioPath
      sNewOrResumeText
      sNewOrResumeAudioPath
      sNewOrResumeRepromtText
      sNewOrResumeRepromtAudioPath
      sStopText
      sStopAudioPath
      sEndOfPlaylistAudioPath
      sEndOfPlaylistExtendedAudioPath
      sAskForNotificationPermissionAudioPath
      sNotificationPermissionConfirmedAudioPath
      sMessageReceivedAudioPath
      sAskForInitialGuidingAudioPath
      sInitialGuidingAudioPath
      sNoInitialGuidingAudioPath
      sAskForMoreActionsHelpAudioPath
      sEpisodeListPrefixAudioPath
      sEpisodeSearchListPrefixAudioPath
      sAskForEpisodeListAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const createRadioContent = /* GraphQL */ `
  mutation CreateRadioContent($input: CreateRadioContentInput!) {
    createRadioContent(input: $input) {
      id
      sAlexaSkillId
      sCatalogId
      aRadio {
        sName
        aSynonyms
        sUrl
        sImagePath
      }
      sLaunchInitialAudioPath
      sLaunchInitialRepromptAudioPath
      sLaunchExtendedAudioPath
      sLaunchShortAudioPath
      sHelpAudioPath
      sHelpExtendedAudioPath
      sHelpRepromptAudioPath
      sContinueAudioPath
      sContinueExtendedAudioPath
      sNoMoreStreamsAudioPath
      sStopAudioPath
      sErrorAudioPath
      sNotSupportedAudioPath
      sUnknownStreamAudioPath
      sFallbackAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const updateRadioContent = /* GraphQL */ `
  mutation UpdateRadioContent($input: UpdateRadioContentInput!) {
    updateRadioContent(input: $input) {
      id
      sAlexaSkillId
      sCatalogId
      aRadio {
        sName
        aSynonyms
        sUrl
        sImagePath
      }
      sLaunchInitialAudioPath
      sLaunchInitialRepromptAudioPath
      sLaunchExtendedAudioPath
      sLaunchShortAudioPath
      sHelpAudioPath
      sHelpExtendedAudioPath
      sHelpRepromptAudioPath
      sContinueAudioPath
      sContinueExtendedAudioPath
      sNoMoreStreamsAudioPath
      sStopAudioPath
      sErrorAudioPath
      sNotSupportedAudioPath
      sUnknownStreamAudioPath
      sFallbackAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const deleteRadioContent = /* GraphQL */ `
  mutation DeleteRadioContent($input: DeleteRadioContentInput!) {
    deleteRadioContent(input: $input) {
      id
      sAlexaSkillId
      sCatalogId
      aRadio {
        sName
        aSynonyms
        sUrl
        sImagePath
      }
      sLaunchInitialAudioPath
      sLaunchInitialRepromptAudioPath
      sLaunchExtendedAudioPath
      sLaunchShortAudioPath
      sHelpAudioPath
      sHelpExtendedAudioPath
      sHelpRepromptAudioPath
      sContinueAudioPath
      sContinueExtendedAudioPath
      sNoMoreStreamsAudioPath
      sStopAudioPath
      sErrorAudioPath
      sNotSupportedAudioPath
      sUnknownStreamAudioPath
      sFallbackAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction($input: CreateActionInput!) {
    createAction(input: $input) {
      id
      sGoogleActionName
      sInvocationName
      sGoogleProjectId
      sLogoLargePath
      sLogoSmallPath
      sActionType
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction($input: UpdateActionInput!) {
    updateAction(input: $input) {
      id
      sGoogleActionName
      sInvocationName
      sGoogleProjectId
      sLogoLargePath
      sLogoSmallPath
      sActionType
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction($input: DeleteActionInput!) {
    deleteAction(input: $input) {
      id
      sGoogleActionName
      sInvocationName
      sGoogleProjectId
      sLogoLargePath
      sLogoSmallPath
      sActionType
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const createPodcastActionContent = /* GraphQL */ `
  mutation CreatePodcastActionContent(
    $input: CreatePodcastActionContentInput!
  ) {
    createPodcastActionContent(input: $input) {
      id
      sGoogleProjectId
      sPodcastRssUrl
      sContactEmail
      bNeedEpisodePrefix
      sFirstLaunchAudioPath
      sLaunchAudioPath
      sLaunchRepromptAudioPath
      sStopAudioPath
      sFallbackAudioPath
      sEndOfPlaylistAudioPath
      sEndOfPlaylistExtendedAudioPath
      sNotificationPermissionConfirmedAudioPath
      sNotificationPermissionNotPossibleAudioPath
      sMessageRecordingAudioPath
      sMessageReceivedAudioPath
      sAskForInitialGuidingAudioPath
      sInitialGuidingAudioPath
      sNoInitialGuidingAudioPath
      sAskForMoreActionsHelpAudioPath
      sEpisodeIntroAudioPath
      sEpisodeListPrefixAudioPath
      sEpisodeSearchListPrefixAudioPath
      sEpisodeNotFoundAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const updatePodcastActionContent = /* GraphQL */ `
  mutation UpdatePodcastActionContent(
    $input: UpdatePodcastActionContentInput!
  ) {
    updatePodcastActionContent(input: $input) {
      id
      sGoogleProjectId
      sPodcastRssUrl
      sContactEmail
      bNeedEpisodePrefix
      sFirstLaunchAudioPath
      sLaunchAudioPath
      sLaunchRepromptAudioPath
      sStopAudioPath
      sFallbackAudioPath
      sEndOfPlaylistAudioPath
      sEndOfPlaylistExtendedAudioPath
      sNotificationPermissionConfirmedAudioPath
      sNotificationPermissionNotPossibleAudioPath
      sMessageRecordingAudioPath
      sMessageReceivedAudioPath
      sAskForInitialGuidingAudioPath
      sInitialGuidingAudioPath
      sNoInitialGuidingAudioPath
      sAskForMoreActionsHelpAudioPath
      sEpisodeIntroAudioPath
      sEpisodeListPrefixAudioPath
      sEpisodeSearchListPrefixAudioPath
      sEpisodeNotFoundAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const deletePodcastActionContent = /* GraphQL */ `
  mutation DeletePodcastActionContent(
    $input: DeletePodcastActionContentInput!
  ) {
    deletePodcastActionContent(input: $input) {
      id
      sGoogleProjectId
      sPodcastRssUrl
      sContactEmail
      bNeedEpisodePrefix
      sFirstLaunchAudioPath
      sLaunchAudioPath
      sLaunchRepromptAudioPath
      sStopAudioPath
      sFallbackAudioPath
      sEndOfPlaylistAudioPath
      sEndOfPlaylistExtendedAudioPath
      sNotificationPermissionConfirmedAudioPath
      sNotificationPermissionNotPossibleAudioPath
      sMessageRecordingAudioPath
      sMessageReceivedAudioPath
      sAskForInitialGuidingAudioPath
      sInitialGuidingAudioPath
      sNoInitialGuidingAudioPath
      sAskForMoreActionsHelpAudioPath
      sEpisodeIntroAudioPath
      sEpisodeListPrefixAudioPath
      sEpisodeSearchListPrefixAudioPath
      sEpisodeNotFoundAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const createCalendarEntry = /* GraphQL */ `
  mutation CreateCalendarEntry($input: CreateCalendarEntryInput!) {
    createCalendarEntry(input: $input) {
      id
      sAlexaSkillId
      sCalendarEntryTitel
      sCalendarEntryType
      sCalendarEntryStartDate
      sCalendarEntryEndDate
      sCalendarEntryContentText
      sCalendarEntryContentAudioPath
      sCalendarEntrySpotifyUrl
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const updateCalendarEntry = /* GraphQL */ `
  mutation UpdateCalendarEntry($input: UpdateCalendarEntryInput!) {
    updateCalendarEntry(input: $input) {
      id
      sAlexaSkillId
      sCalendarEntryTitel
      sCalendarEntryType
      sCalendarEntryStartDate
      sCalendarEntryEndDate
      sCalendarEntryContentText
      sCalendarEntryContentAudioPath
      sCalendarEntrySpotifyUrl
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const deleteCalendarEntry = /* GraphQL */ `
  mutation DeleteCalendarEntry($input: DeleteCalendarEntryInput!) {
    deleteCalendarEntry(input: $input) {
      id
      sAlexaSkillId
      sCalendarEntryTitel
      sCalendarEntryType
      sCalendarEntryStartDate
      sCalendarEntryEndDate
      sCalendarEntryContentText
      sCalendarEntryContentAudioPath
      sCalendarEntrySpotifyUrl
      sOwner
      createdAt
      updatedAt
    }
  }
`;

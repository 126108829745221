/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const triggerMp3Conversion = /* GraphQL */ `
  query TriggerMp3Conversion($sTitle: String!, $sMP3: ID!) {
    triggerMp3Conversion(sTitle: $sTitle, sMP3: $sMP3) {
      sMP3
      bResultSuccessfull
    }
  }
`;
export const getPodcastAnalytics = /* GraphQL */ `
  query GetPodcastAnalytics($sSkillID: ID!) {
    getPodcastAnalytics(sSkillID: $sSkillID) {
      sSkillID
      sTitle
      iUserCount
      iUserWithNotifications
      iCurrentActiveUser
      iUserWithScreens
    }
  }
`;
export const listPodcastAnalyticsUsers = /* GraphQL */ `
  query ListPodcastAnalyticsUsers($sSkillID: ID!) {
    listPodcastAnalyticsUsers(sSkillID: $sSkillID) {
      sSkillID
      sDate
      iUserCount
    }
  }
`;
export const listPodcastAnalyticsEpisodes = /* GraphQL */ `
  query ListPodcastAnalyticsEpisodes($sSkillID: ID!) {
    listPodcastAnalyticsEpisodes(sSkillID: $sSkillID) {
      sSkillID
      sEpisodeGUID
      sEpisodeTitle
      sEpisodeNumber
      iUserWhoFinishedEpisode
      iUserWhoStartsEpisode
      iEpisodeDurationInMin
      iSummarizedPlayedMinutes
    }
  }
`;
export const getDashboardTableData = /* GraphQL */ `
  query GetDashboardTableData($params: String) {
    getDashboardTableData(params: $params) {
      items {
        title
        author
        ownerName
        ownerMail
        episodeCount
        primaryGenreName
        releaseDate
        releaseYear
        changeDate
        changeYear
        language
        feedDomain
        feedUrl
        feedTld
        iTunesId
        link
        description
      }
      count
    }
  }
`;
export const getDashboardGraphData = /* GraphQL */ `
  query GetDashboardGraphData($params: String) {
    getDashboardGraphData(params: $params) {
      amountPodcasts
      amountPodcaster
      podcastsCreatedDoghnutData {
        amount
        label
      }
      topCategoriesData {
        title
        amount
      }
      topPodcasterData {
        amount
        label
      }
      createdCompareData {
        actualYear {
          amount
          label
        }
        lastYear {
          amount
          label
        }
      }
      podcastCreatedUpdatedData {
        created {
          date
          amount
        }
        updated {
          date
          amount
        }
      }
    }
  }
`;
export const getDateRanges = /* GraphQL */ `
  query GetDateRanges {
    getDateRanges {
      created {
        min
        max
      }
      updated {
        min
        max
      }
    }
  }
`;
export const smapiGetSkillStatus = /* GraphQL */ `
  query SmapiGetSkillStatus($sVendorID: ID!, $sSkillID: ID!) {
    SMAPIGetSkillStatus(sVendorID: $sVendorID, sSkillID: $sSkillID) {
      sPublicationStatusDevelopment
      sPublicationStatusLive
      sStatusInteractionModel
      sStatusManifest
    }
  }
`;
export const smapiGetLanguageModel = /* GraphQL */ `
  query SmapiGetLanguageModel($sSkillID: ID!) {
    SMAPIGetLanguageModel(sSkillID: $sSkillID)
  }
`;
export const getPodcast = /* GraphQL */ `
  query GetPodcast($id: ID!) {
    getPodcast(id: $id) {
      id
      podcastTitle
      invocationName
      alexaSkillId
      podcastRssUrl
      email
      startPlayInitText
      startPlayInitAudioPath
      startPlayNewText
      startPlayNewAudioPath
      startPlayRepromptText
      startPlayRepromptAudioPath
      startResumeText
      startResumeAudioPath
      startResumeRepromptText
      startResumeRepromptAudioPath
      finishText
      finishAudioPath
      endOfPlaylistAudioPath
      endOfPlaylistExtendedAudioPath
      notificationPermissionAudioPath
      notificationActivatedAudioPath
      messageReceivedAudioPath
      askForGuidingAudioPath
      guidingAudioPath
      noGuidingAudioPath
      askForMoreActionsAudioPath
      logoLargePath
      logoSmallPath
      backgroundImageLargePath
      backgroundImageSmallPath
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listPodcasts = /* GraphQL */ `
  query ListPodcasts(
    $filter: ModelPodcastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodcasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        podcastTitle
        invocationName
        alexaSkillId
        podcastRssUrl
        email
        startPlayInitText
        startPlayInitAudioPath
        startPlayNewText
        startPlayNewAudioPath
        startPlayRepromptText
        startPlayRepromptAudioPath
        startResumeText
        startResumeAudioPath
        startResumeRepromptText
        startResumeRepromptAudioPath
        finishText
        finishAudioPath
        endOfPlaylistAudioPath
        endOfPlaylistExtendedAudioPath
        notificationPermissionAudioPath
        notificationActivatedAudioPath
        messageReceivedAudioPath
        askForGuidingAudioPath
        guidingAudioPath
        noGuidingAudioPath
        askForMoreActionsAudioPath
        logoLargePath
        logoSmallPath
        backgroundImageLargePath
        backgroundImageSmallPath
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($id: ID!) {
    getSkill(id: $id) {
      id
      sAlexaSkillName
      sInvocationName
      sAlexaSkillId
      sLogoLargePath
      sLogoSmallPath
      sBackgroundImageLargePath
      sBackgroundImageSmallPath
      sSkillType
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sAlexaSkillName
        sInvocationName
        sAlexaSkillId
        sLogoLargePath
        sLogoSmallPath
        sBackgroundImageLargePath
        sBackgroundImageSmallPath
        sSkillType
        sOwner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPodcastContent = /* GraphQL */ `
  query GetPodcastContent($id: ID!) {
    getPodcastContent(id: $id) {
      id
      sAlexaSkillId
      sPodcastRssUrl
      sContactEmail
      bNeedEpisodePrefix
      sFirstLaunchStartText
      sFirstLaunchAudioPath
      sLaunchStartText
      sLaunchStartAudioPath
      sLaunchStartRepromtText
      sLaunchStartRepromtAudioPath
      sLaunchResumeText
      sLaunchResumeAudioPath
      sLaunchResumeRepromtText
      sLaunchResumeRepromtAudioPath
      sNewOrResumeText
      sNewOrResumeAudioPath
      sNewOrResumeRepromtText
      sNewOrResumeRepromtAudioPath
      sStopText
      sStopAudioPath
      sEndOfPlaylistAudioPath
      sEndOfPlaylistExtendedAudioPath
      sAskForNotificationPermissionAudioPath
      sNotificationPermissionConfirmedAudioPath
      sMessageReceivedAudioPath
      sAskForInitialGuidingAudioPath
      sInitialGuidingAudioPath
      sNoInitialGuidingAudioPath
      sAskForMoreActionsHelpAudioPath
      sEpisodeListPrefixAudioPath
      sEpisodeSearchListPrefixAudioPath
      sAskForEpisodeListAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const listPodcastContents = /* GraphQL */ `
  query ListPodcastContents(
    $filter: ModelPodcastContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodcastContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sAlexaSkillId
        sPodcastRssUrl
        sContactEmail
        bNeedEpisodePrefix
        sFirstLaunchStartText
        sFirstLaunchAudioPath
        sLaunchStartText
        sLaunchStartAudioPath
        sLaunchStartRepromtText
        sLaunchStartRepromtAudioPath
        sLaunchResumeText
        sLaunchResumeAudioPath
        sLaunchResumeRepromtText
        sLaunchResumeRepromtAudioPath
        sNewOrResumeText
        sNewOrResumeAudioPath
        sNewOrResumeRepromtText
        sNewOrResumeRepromtAudioPath
        sStopText
        sStopAudioPath
        sEndOfPlaylistAudioPath
        sEndOfPlaylistExtendedAudioPath
        sAskForNotificationPermissionAudioPath
        sNotificationPermissionConfirmedAudioPath
        sMessageReceivedAudioPath
        sAskForInitialGuidingAudioPath
        sInitialGuidingAudioPath
        sNoInitialGuidingAudioPath
        sAskForMoreActionsHelpAudioPath
        sEpisodeListPrefixAudioPath
        sEpisodeSearchListPrefixAudioPath
        sAskForEpisodeListAudioPath
        sOwner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRadioContent = /* GraphQL */ `
  query GetRadioContent($id: ID!) {
    getRadioContent(id: $id) {
      id
      sAlexaSkillId
      sCatalogId
      aRadio {
        sName
        aSynonyms
        sUrl
        sImagePath
      }
      sLaunchInitialAudioPath
      sLaunchInitialRepromptAudioPath
      sLaunchExtendedAudioPath
      sLaunchShortAudioPath
      sHelpAudioPath
      sHelpExtendedAudioPath
      sHelpRepromptAudioPath
      sContinueAudioPath
      sContinueExtendedAudioPath
      sNoMoreStreamsAudioPath
      sStopAudioPath
      sErrorAudioPath
      sNotSupportedAudioPath
      sUnknownStreamAudioPath
      sFallbackAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const listRadioContents = /* GraphQL */ `
  query ListRadioContents(
    $filter: ModelRadioContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRadioContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sAlexaSkillId
        sCatalogId
        aRadio {
          sName
          aSynonyms
          sUrl
          sImagePath
        }
        sLaunchInitialAudioPath
        sLaunchInitialRepromptAudioPath
        sLaunchExtendedAudioPath
        sLaunchShortAudioPath
        sHelpAudioPath
        sHelpExtendedAudioPath
        sHelpRepromptAudioPath
        sContinueAudioPath
        sContinueExtendedAudioPath
        sNoMoreStreamsAudioPath
        sStopAudioPath
        sErrorAudioPath
        sNotSupportedAudioPath
        sUnknownStreamAudioPath
        sFallbackAudioPath
        sOwner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      sGoogleActionName
      sInvocationName
      sGoogleProjectId
      sLogoLargePath
      sLogoSmallPath
      sActionType
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sGoogleActionName
        sInvocationName
        sGoogleProjectId
        sLogoLargePath
        sLogoSmallPath
        sActionType
        sOwner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPodcastActionContent = /* GraphQL */ `
  query GetPodcastActionContent($id: ID!) {
    getPodcastActionContent(id: $id) {
      id
      sGoogleProjectId
      sPodcastRssUrl
      sContactEmail
      bNeedEpisodePrefix
      sFirstLaunchAudioPath
      sLaunchAudioPath
      sLaunchRepromptAudioPath
      sStopAudioPath
      sFallbackAudioPath
      sEndOfPlaylistAudioPath
      sEndOfPlaylistExtendedAudioPath
      sNotificationPermissionConfirmedAudioPath
      sNotificationPermissionNotPossibleAudioPath
      sMessageRecordingAudioPath
      sMessageReceivedAudioPath
      sAskForInitialGuidingAudioPath
      sInitialGuidingAudioPath
      sNoInitialGuidingAudioPath
      sAskForMoreActionsHelpAudioPath
      sEpisodeIntroAudioPath
      sEpisodeListPrefixAudioPath
      sEpisodeSearchListPrefixAudioPath
      sEpisodeNotFoundAudioPath
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const listPodcastActionContents = /* GraphQL */ `
  query ListPodcastActionContents(
    $filter: ModelPodcastActionContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodcastActionContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sGoogleProjectId
        sPodcastRssUrl
        sContactEmail
        bNeedEpisodePrefix
        sFirstLaunchAudioPath
        sLaunchAudioPath
        sLaunchRepromptAudioPath
        sStopAudioPath
        sFallbackAudioPath
        sEndOfPlaylistAudioPath
        sEndOfPlaylistExtendedAudioPath
        sNotificationPermissionConfirmedAudioPath
        sNotificationPermissionNotPossibleAudioPath
        sMessageRecordingAudioPath
        sMessageReceivedAudioPath
        sAskForInitialGuidingAudioPath
        sInitialGuidingAudioPath
        sNoInitialGuidingAudioPath
        sAskForMoreActionsHelpAudioPath
        sEpisodeIntroAudioPath
        sEpisodeListPrefixAudioPath
        sEpisodeSearchListPrefixAudioPath
        sEpisodeNotFoundAudioPath
        sOwner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCalendarEntry = /* GraphQL */ `
  query GetCalendarEntry($id: ID!) {
    getCalendarEntry(id: $id) {
      id
      sAlexaSkillId
      sCalendarEntryTitel
      sCalendarEntryType
      sCalendarEntryStartDate
      sCalendarEntryEndDate
      sCalendarEntryContentText
      sCalendarEntryContentAudioPath
      sCalendarEntrySpotifyUrl
      sOwner
      createdAt
      updatedAt
    }
  }
`;
export const listCalendarEntrys = /* GraphQL */ `
  query ListCalendarEntrys(
    $filter: ModelCalendarEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarEntrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sAlexaSkillId
        sCalendarEntryTitel
        sCalendarEntryType
        sCalendarEntryStartDate
        sCalendarEntryEndDate
        sCalendarEntryContentText
        sCalendarEntryContentAudioPath
        sCalendarEntrySpotifyUrl
        sOwner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

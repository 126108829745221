import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Label, Color } from 'ng2-charts';

import { AppsyncService } from '../services/appsync.service';

@Component({
  selector: 'app-podcast-analytics',
  templateUrl: './podcast-analytics.component.html',
  styleUrls: ['./podcast-analytics.component.scss']
})
export class PodcastAnalyticsComponent implements OnInit {
  private aMonths = ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'];
  private aUserWithScreenLabels: Label[] = ['Nutzer mit Bildschirm', 'Nutzer ohne Bildschirm'];
  private aUserWithNotificationLabels: Label[] = ['Nutzer mit Benachrichtigung', 'Nutzer ohne Benachrichtigung'];
  private aUserNumbersLabels: Label[] = [];
  private aChartColors: Color[] = [
    {
      backgroundColor: ['rgba(1,104,122,1)', 'rgba(148,159,177,0.2)'],
      borderColor: ['rgba(1,84,102,1)', 'rgba(1,84,102,1)'],
      pointBackgroundColor: ['rgba(1,84,102,1)', 'rgba(148,159,177,1)'],
      pointBorderColor: ['#fff', '#fff'],
      pointHoverBackgroundColor: ['#fff', '#fff'],
      pointHoverBorderColor: ['rgba(1,84,102,1)', 'rgba(148,159,177,0.8)']
    }
  ];

  private aLineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(1,104,122,1)',
      borderColor: 'rgba(1,84,102,1)',
      pointBackgroundColor: 'rgba(1,84,102,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(1,84,102,1)'
    }
  ];
  private aColumnsToDisplay = ['title', 'userStarted', 'userFinished', 'duration', 'playedSummarized'];

  sCurrentId: string;
  oPodcastAnalytics = {};
  aPodcastAnalyticsEpisodes = [];
  aUserWithScreenData = [];
  aUserWithNotificationData = [];
  aUserNumbersDataSets = [{ data: [], label: 'Nutzerentwicklung' }];
  tableDataSource = new MatTableDataSource<any>([]);

  constructor(
    private route: ActivatedRoute,
    private appsyncService: AppsyncService
  ) {
    this.route.params.subscribe((params = {}) => {
      if (!this.sCurrentId) {
        this.sCurrentId = params.id;
        this.getAnalyticsData();
      } else if (this.sCurrentId !== params.id) {
        this.sCurrentId = params.id;
        this.getAnalyticsData();
      }
    });
  }

  ngOnInit() {

  }

  // Refresh table (else nothing would be displayed after requesting Episodes-List)
  refreshTable() {
    this.tableDataSource = new MatTableDataSource<any>(this.aPodcastAnalyticsEpisodes);
  }

  getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    // @ts-ignore
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    // return [d.getUTCFullYear(), weekNo];
    return `KW${weekNo} ${d.getUTCFullYear()}`;
  }

  async getAnalyticsData() {
    try {
      this.oPodcastAnalytics = await this.appsyncService.getPodcastAnalytics(this.sCurrentId);
      this.aUserWithScreenData = [
        // @ts-ignore
        this.oPodcastAnalytics.iUserWithScreens,
        // @ts-ignore
        this.oPodcastAnalytics.iUserCount - this.oPodcastAnalytics.iUserWithScreens
      ];
      this.aUserWithNotificationData = [
        // @ts-ignore
        this.oPodcastAnalytics.iUserWithNotifications,
        // @ts-ignore
        this.oPodcastAnalytics.iUserCount - this.oPodcastAnalytics.iUserWithNotifications
      ];

      const aPodcastAnalyticsUsers = await this.appsyncService.listPodcastAnalyticsUsers(this.sCurrentId);
      aPodcastAnalyticsUsers.forEach(oUsers => {
        this.aUserNumbersDataSets[0].data.push(oUsers.iUserCount);
        const dDate = new Date(oUsers.sDate);
        this.aUserNumbersLabels.push(`${this.getWeekNumber(dDate)}`);
      });

      this.aPodcastAnalyticsEpisodes = await this.appsyncService.listPodcastAnalyticsEpisodes(this.sCurrentId);
      this.refreshTable();
    } catch (oError) {
      console.error(oError);
    }
  }

}

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { } from '../../API';
import { AppsyncService } from '../services/appsync.service';
import { S3Service } from '../services/s3.service';

/**
 * Popup Component
 */

@Component({
  selector: 'app-podcast-list-popup',
  templateUrl: 'app-podcast-list-popup.html',
})
export class PodcastListPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<PodcastListPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(DOCUMENT) public document
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

/**
 * List Component
 */

@Component({
  selector: 'app-podcast-list',
  templateUrl: './podcast-list.component.html',
  styleUrls: ['./podcast-list.component.scss']
})
export class PodcastListComponent implements OnInit {
  @Output()
  loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  private aColumnsToDisplay = ['image', 'podcastTitle', 'podcastRssUrl', 'buttons'];

  aPodcasts = [];
  dataSource = new MatTableDataSource<any>([]);

  constructor(
    private appsyncService: AppsyncService,
    private s3Service: S3Service,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getPodcastList();
  }

  // Get all Podcasts (up to 1000) of logged in user (or all for admin)
  async getPodcastList(): Promise<any> {
    try {
      this.aPodcasts = await this.appsyncService.listPodcasts();
      this.refreshTable();
      this.setLogoUrls();
    } catch (oError) { }
  }

  // Delete Podcast from Dynamo
  deletePodcast(sId: string, sPodcastTitle) {
    const dialogRef = this.dialog.open(PodcastListPopupComponent, {
      width: '400px',
      data: { id: sId, podcastTitle: sPodcastTitle }
    });

    dialogRef.afterClosed().subscribe(async bDelete => {
      if (bDelete) {
        try {
          await this.appsyncService.deletePodcast(sId);
          this.getPodcastList();
        } catch { }
      } else {
        console.log('No Delete');
      }
    });
  }

  // Refresh table (else nothing would be displayed after requesting Podcast-List)
  refreshTable() {
    this.dataSource = new MatTableDataSource<any>(this.aPodcasts);
  }

  // Get and set all Logo-URLs
  setLogoUrls() {
    this.aPodcasts.forEach(async oPodcast => {
      try {
        oPodcast.logoPath = await this.s3Service.getFileUrl(oPodcast.logoSmallPath);
      } catch (oError) { }
    });
  }
}

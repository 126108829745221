// Get type of file
export function getFileType(fileName: string) {
    const re = /(?:\.([^.]+))?$/;
    return fileName === null || fileName === undefined ? null : re.exec(fileName)[1];
}

// Get Path of uploaded file (without folder of S3 bucket)
export function getFilePath(sPath) {
    const re = /([^\/]+$)/;
    return !sPath ? undefined : re.exec(sPath)[1];
}

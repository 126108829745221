export class SkillType {
    constructor(
        public sOwner?: string,
        public id?: string,
        public sAlexaSkillName?: string,
        public sInvocationName?: string,
        public sAlexaSkillId?: string,
        public sLogoLargePath?: string,
        public sLogoSmallPath?: string,
        public sBackgroundImageLargePath?: string,
        public sBackgroundImageSmallPath?: string,
        public sSkillType?: string,
        public createdAt?: string,
        public updatedAt?: string
    ) { }
}

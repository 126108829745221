export function getErrorRequired(form) {
    return form.hasError('required') ? 'Das ist ein Pflichtfeld' : '';
}

export function getErrorEmail(form) {
    return getErrorRequired(form) ? getErrorRequired(form) :
        form.hasError('noEmail') ? 'Bitte wähle eine valide E-Mail-Adresse' : '';
}

export function getErrorUrl(form) {
    return getErrorRequired(form) ? getErrorRequired(form) :
        form.hasError('noUrl') ? 'Bitte gib eine valide URL ein' : '';
}

export function getErrorGoogleActionName(form) {
    return getErrorRequired(form) ? getErrorRequired(form) :
        form.hasError('alreadyUsed') ? 'Dieser Actionname wird bereits verwendet' :
            form.hasError('error') ? 'Fehler bei der Überprüfung des Actionnames' : '';
}

export function getErrorGoogleProjectId(form) {
    return getErrorRequired(form) ? getErrorRequired(form) :
        form.hasError('noProjectId') ? 'Bitte gib eine valide Google Projekt ID ein (z.B. projectname-xxxxx)' : '';
}

export function getErrorAlexaCatalogId(form) {
    return getErrorRequired(form) ? getErrorRequired(form) :
        // tslint:disable-next-line: max-line-length
        form.hasError('noCatalogId') ? 'Bitte gib eine valide Alexa Catalog ID ein (amzn1.ask.interactionModel.catalog.xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)' : '';
}

export function getErrorAlexaSkillId(form) {
    return getErrorRequired(form) ? getErrorRequired(form) :
        form.hasError('noSkillId') ? 'Bitte gib eine valide Alexa Skill ID ein (amzn1.ask.skill.xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)' : '';
}

export function getErrorAlexaSkillName(form) {
    return getErrorRequired(form) ? getErrorRequired(form) :
        form.hasError('alreadyUsed') ? 'Dieser Skillname wird bereits verwendet' :
            form.hasError('error') ? 'Fehler bei der Überprüfung des Skillnames' : '';
}

export function getErrorLogo(form) {
    return getErrorRequired(form) ? getErrorRequired(form) :
        form.hasError('wrongFileType') ? 'Bitte wähle ein Bild im PNG-Format' : '';
}

export function getErrorAudio(form) {
    return form.hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
}

export function getErrorAlexaSynonym(form) {
    return form.hasError('invalidCharacter') ? 'Es sind nur Buchstaben erlaubt (keine Zahlen oder Sonderzeichen)' :
        form.hasError('tooLong') ? 'Es sind maximal 140 Zeichen pro Synonym erlaubt' : '';
}

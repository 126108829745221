import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CalendarEvent
} from 'angular-calendar';
import { Bool } from 'aws-sdk/clients/clouddirectory';
import { S3Service } from '../services/s3.service';
import { AppsyncService } from '../services/appsync.service';
import {
  CreateCalendarEntryInput, UpdateCalendarEntryInput
} from '../../API';
import { Auth } from 'aws-amplify';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface DialogData {
  sAlexaSkillId: string,
  calendarEntryId: string,
  calendarEntryTitel: string;
  calendarEntryType: string;
  calendarEntryStartDate: Date;
  calendarEntryEndDate: Date;
  calendarEntryContent: [];
  // calendarEntryContentText: string;
  // calendarEntryContentAudioPath: string;
  calendarEntrySpotifyUrl: string;
}

export interface SkillType {
  value: string;
  viewValue: string;
}
const colors: any = {
  red: {
    primary: '#ad2121'
  },
  blue: {
    primary: '#1e90ff'
  },
  yellow: {
    primary: '#e3bc08'
  }
};

@Component({
  selector: 'app-vcms-calendar-entry',
  templateUrl: './vcms-calendar-entry.component.html',
  styleUrls: ['./vcms-calendar-entry.component.scss'],

})
export class VcmsCalendarEntryComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private appsyncService: AppsyncService,
    private s3Service: S3Service,
    public dialogRef: MatDialogRef<VcmsCalendarEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
  formGroup: FormGroup;
  aSkillTypes: SkillType[] = [
    { value: 'News', viewValue: 'News' },
    { value: 'Spotlight', viewValue: 'Spotlight' }
  ];
  bCreateMode: Bool;
  sCalendarEntryId: string;
  busy: Promise<any>;
  busymessage: string;
  sAlexaSkillId: string;

  ngOnInit() {
    this.busymessage = 'Saving...';
    this.sAlexaSkillId = this.data.sAlexaSkillId;

    console.log(this.dialogRef);
    if (this.data.calendarEntryId == undefined) {
      this.sCalendarEntryId = this.uuidv4();
      this.bCreateMode = true;
    }
    else {
      this.sCalendarEntryId = this.data.calendarEntryId;
      this.bCreateMode = false;
    }

    this.formGroup = this.formBuilder.group({
      calendarEntryTitel: [this.data.calendarEntryTitel, [Validators.required]],
      calendarEntryType: [this.data.calendarEntryType, [Validators.required]],
      calendarEntryStartDate: [this.data.calendarEntryStartDate, [Validators.required]],
      calendarEntryEndDate: [this.data.calendarEntryEndDate, [Validators.required]],

      calendarEntryContent: this.formBuilder.array([]),

      // calendarEntryContentText: [this.data.calendarEntryContentText, [Validators.required]],
      // calendarEntryContentAudioPath: [this.data.calendarEntryContentAudioPath],
      // calendarEntryContentAudioInput: [null, [this.checkAudioType.bind(this)]],

      calendarEntrySpotifyUrl: [this.data.calendarEntrySpotifyUrl],
    });

    if (this.data.calendarEntryContent) {
      this.data.calendarEntryContent.forEach(oCalendarEntryContentEntry => {
        // @ts-ignore
        var sCalendarEntryContentText = oCalendarEntryContentEntry.calendarEntryContentText;
        // @ts-ignore
        var sCalendarEntryContentAudioPath = oCalendarEntryContentEntry.calendarEntryContentAudioPath;
        if (sCalendarEntryContentText) {
          this.addInput(this.createInputText(sCalendarEntryContentText));
        }
        if (sCalendarEntryContentAudioPath) {
          this.addInput(this.createInputAudio(sCalendarEntryContentAudioPath));
        }

        console.log(oCalendarEntryContentEntry);
      });
    }
  }

  getErrorWrongFileType(index) {
    return this.formGroup.get('calendarEntryContent').get([index]).get('calendarEntryContentAudioInput').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
    // return this.formGroup.get('calendarEntryContent').hasError('wrongFileType') ? 'Bitte wähle eine Audiodatei im MP3-Format' : '';
  }

  addInput(oInput): void {
    // @ts-ignore
    this.formGroup.get('calendarEntryContent').push(oInput);
  }

  createInputText(sValue): FormGroup {
    if (sValue == undefined)
      sValue = '';
    return this.formBuilder.group({
      calendarEntryContentText: [sValue],
    });
  }

  createInputAudio(sAudioPath): FormGroup {
    if (sAudioPath)
      return this.formBuilder.group({
        calendarEntryContentAudioPath: [sAudioPath],
        calendarEntryContentAudioInput: [null, [this.checkAudioType.bind(this)]],
      });
    else
      return this.formBuilder.group({
        calendarEntryContentAudioPath: [],
        calendarEntryContentAudioInput: [null, [this.checkAudioType.bind(this)]],
      });
  }
  deleteInput(iIndex): void {
    // @ts-ignore
    this.formGroup.get('calendarEntryContent').removeAt(iIndex);
  }

  drop(event: CdkDragDrop<any>) {
    console.log('abc');
    // @ts-ignore
    moveItemInArray(this.formGroup.get('calendarEntryContent').controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.formGroup.get('calendarEntryContent').value, event.previousIndex, event.currentIndex);
    console.log('def');

  }


  getErrorCalendarEntryRequired(sFieldname: string) {
    return this.formGroup.get(sFieldname).hasError('required') ? 'Pflichtfeld' : '';
  }

  // Get Path of uploaded file (without folder of S3 bucket)
  getFilePath(sPath) {
    const re = /\/(.+)$/;
    return sPath === null || sPath === undefined ? null : re.exec(sPath)[1];
  }

  // Validation for MP3 filetype
  checkAudioType(oControl) {
    if (oControl.value) {
      const sFileType = this.getFileType(oControl.value.fileNames);
      return sFileType === 'mp3' ? null : { wrongFileType: true };
    } else {
      return null;
    }
  }

  // Get type of file
  getFileType(sFileName) {
    const re = /(?:\.([^.]+))?$/;
    return sFileName === null || sFileName === undefined ? null : re.exec(sFileName)[1];
  }
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  clearFile(oEvent, index, sFormControl) {
    // this.formGroup.get(sFormControl).reset();
    this.formGroup.get('calendarEntryContent').get([index]).get(sFormControl).reset();
    // this.formGroup.get('calendarEntryContent').value[index][sFormControl] = null;
  }
  saveChanges(): void {
    const wait = ms => new Promise(resolve => setTimeout(async () => {

      const oUser = await Auth.currentAuthenticatedUser();
      var sKey = '';
      var aContent = [];
      for (var i = 0; i < this.formGroup.value.calendarEntryContent.length; i++) {
        var sFileName;
        var oContentEntry = this.formGroup.value.calendarEntryContent[i];
        if (oContentEntry.calendarEntryContentAudioInput != undefined) {
          var oFormGroupInput = oContentEntry.calendarEntryContentAudioInput;
          if (oFormGroupInput && oFormGroupInput !== null) {
            var sPodcastTitle = 'Muster Podcast';
            this.busymessage = `Uploading ${oFormGroupInput.fileNames}`;

            sKey = await this.s3Service.uploadFile(oFormGroupInput.files[0], `${sPodcastTitle}/calendar/${oFormGroupInput.fileNames}`);
          };
          this.busymessage = `Converting ${oFormGroupInput.fileNames}`;
          sFileName = await this.appsyncService.MP3Conversion(`${sPodcastTitle}/calendar`, oFormGroupInput.fileNames);
        }

        if (oContentEntry.calendarEntryContentText) {
          aContent.push({ calendarEntryContentText: oContentEntry.calendarEntryContentText });
        }
        if (oContentEntry.calendarEntryContentAudioPath) {
          aContent.push({ calendarEntryContentAudioPath: oContentEntry.calendarEntryContentAudioPath });
        }
        if (sFileName) {
          aContent.push({ calendarEntryContentAudioPath: sKey });
        }
      };

      var oCalendarEventStart = new Date(this.formGroup.get('calendarEntryStartDate').value)
      var oCalendarEventEnd = new Date(this.formGroup.get('calendarEntryEndDate').value);

      // if (this.bCreateMode) {
      //   var oCreateCalendarEntryInput: CreateCalendarEntryInput = {
      //     id: this.sCalendarEntryId,
      //     sAlexaSkillId: this.sAlexaSkillId,
      //     sCalendarEntryContentAudioPath: sKey,
      //     sCalendarEntryContentText: this.formGroup.get('calendarEntryContentText').value,
      //     sCalendarEntryStartDate: oCalendarEventStart.toISOString(),
      //     sCalendarEntryEndDate: oCalendarEventEnd.toISOString(),
      //     sCalendarEntrySpotifyUrl: this.formGroup.get('calendarEntrySpotifyUrl').value,
      //     sCalendarEntryTitel: this.formGroup.get('calendarEntryTitel').value,
      //     sCalendarEntryType: this.formGroup.get('calendarEntryType').value,
      //     sOwner: oUser.attributes.email
      //   }
      //   await this.appsyncService.createCalendarEntry(oCreateCalendarEntryInput);
      // } else {
      //   var oUpdateCalendarEntryInput: UpdateCalendarEntryInput = {
      //     id: this.sCalendarEntryId,
      //     sAlexaSkillId: this.sAlexaSkillId,
      //     sCalendarEntryContentAudioPath: sKey,
      //     sCalendarEntryContentText: this.formGroup.get('calendarEntryContentText').value,
      //     sCalendarEntryStartDate: oCalendarEventStart.toISOString(),
      //     sCalendarEntryEndDate: oCalendarEventEnd.toISOString(),
      //     sCalendarEntrySpotifyUrl: this.formGroup.get('calendarEntrySpotifyUrl').value,
      //     sCalendarEntryTitel: this.formGroup.get('calendarEntryTitel').value,
      //     sCalendarEntryType: this.formGroup.get('calendarEntryType').value,
      //     sOwner: oUser.attributes.email
      //   }
      //   await this.appsyncService.updateCalendarEntry(oUpdateCalendarEntryInput);
      // }


      var oCalendarEvent: CalendarEvent = {
        id: this.sCalendarEntryId,
        title: this.formGroup.get('calendarEntryTitel').value,
        start: oCalendarEventStart,
        end: oCalendarEventEnd,
        color: this.formGroup.get('calendarEntryType').value == 'News' ? colors.red : colors.blue,
        meta: {
          sAlexaSkillId: this.sAlexaSkillId,
          calendarEntryContent: aContent,
          calendarEntryType: this.formGroup.get('calendarEntryType').value,
          // calendarEntryContentText: this.formGroup.get('calendarEntryContentText').value,
          // calendarEntryContentAudioPath: sKey,
          calendarEntrySpotifyUrl: this.formGroup.get('calendarEntrySpotifyUrl').value,
        },
        allDay: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        },
        draggable: true
      };


      this.dialogRef.close(oCalendarEvent);
      resolve();
    }, ms));
    this.busy = wait(1000);
  }
}

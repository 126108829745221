import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VcmsCalendarEntryComponent } from '../vcms-calendar-entry/vcms-calendar-entry.component';
import { ActivatedRoute } from '@angular/router';

import { ViewChild, TemplateRef } from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';

import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';

const colors: any = {
    red: {
        primary: '#ad2121'
    },
    blue: {
        primary: '#1e90ff'
    },
    yellow: {
        primary: '#e3bc08'
    }
};

@Component({
    selector: 'app-vcms-calendar',
    templateUrl: './vcms-calendar.component.html',
    styleUrls: ['./vcms-calendar.component.scss']
})
export class VcmsCalendarComponent implements OnInit {
    @Input() sAlexaSkillId: string;
    @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
    @ViewChild('modalCalendarEntry', { static: true }) modalCalendarEntry: TemplateRef<any>;

    constructor(public dialog: MatDialog, private route: ActivatedRoute) {}
    async ngOnInit() {
        this.events = [];

        this.route.params.subscribe(async (params = {}) => {
            this.sAlexaSkillId = params.id;

            // var aCalendarEntries = await this.appsyncService.getCalendarEntries(this.sAlexaSkillId);
            var aCalendarEntries = [];
            this.events = [];
            for (var i = 0; i < aCalendarEntries.length; i++) {
                var oCalendarEntry = aCalendarEntries[i];
                var oStartDate = new Date(oCalendarEntry.sCalendarEntryStartDate.substring(0, 10) + 'T00:00:00.000Z');
                var oEndDate = new Date(oCalendarEntry.sCalendarEntryEndDate.substring(0, 10) + 'T00:00:00.000Z');

                var oCalendarEvent: CalendarEvent = {
                    id: oCalendarEntry.id,
                    start: oStartDate, //oStartDate, //subDays(startOfDay(new Date()), 1),
                    end: oEndDate, //oEndDate,
                    title: oCalendarEntry.sCalendarEntryTitel,
                    color: oCalendarEntry.sCalendarEntryType == 'News' ? colors.red : colors.blue,
                    meta: {
                        sAlexaSkillId: oCalendarEntry.sAlexaSkillId,
                        calendarEntryType: oCalendarEntry.sCalendarEntryType,
                        calendarEntryContentText: oCalendarEntry.sCalendarEntryContentText,
                        calendarEntryContentAudioPath: oCalendarEntry.sCalendarEntryContentAudioPath,
                        calendarEntrySpotifyUrl: oCalendarEntry.sCalendarEntrySpotifyUrl
                    },
                    actions: this.actions,
                    allDay: true,
                    resizable: {
                        beforeStart: true,
                        afterEnd: true
                    },
                    draggable: true
                };
                this.events.push(oCalendarEvent);
            }
        });
    }

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    viewDate: Date = new Date();
    modalData: {
        action: string;
        event: CalendarEvent;
    };

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fa fa-fw fa-pencil" style="color:white"></i>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            }
        },
        {
            label: '<i class="fa fa-fw fa-times" style="color:white"></i>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.deleteEvent(event);
                // this.events = this.events.filter(iEvent => iEvent !== event);
                this.handleEvent('Deleted', event);
            }
        }
    ];

    refresh: Subject<any> = new Subject();

    event: CalendarEvent = {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
            beforeStart: true,
            afterEnd: true
        },
        meta: {
            alexa: ''
        }
    };

    events: CalendarEvent[] = [];

    activeDayIsOpen: boolean = true;

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    saveEvent() {}

    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map(iEvent => {
            if (iEvent === event) {
                var oUpdatedEvent = {
                    ...event,
                    start: newStart,
                    end: newEnd
                };
                this.saveEvent();
                return oUpdatedEvent;
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {
        this.modalData = { event, action };
        if (action == 'Edited') {
            const dialogRef = this.dialog.open(VcmsCalendarEntryComponent, {
                data: {
                    calendarEntryId: event.id,
                    calendarEntryTitel: event.title,
                    calendarEntryType: event.meta.calendarEntryType,
                    calendarEntryStartDate: event.start,
                    calendarEntryEndDate: event.end,
                    calendarEntryContent: event.meta.calendarEntryContent,
                    // calendarEntryContentText: event.meta.calendarEntryContentText,
                    calendarEntrySpotifyUrl: event.meta.calendarEntrySpotifyUrl
                    // calendarEntryContentAudioPath: event.meta.calendarEntryContentAudioPath
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result == undefined) return;
                console.log(`Dialog result: ${result}`);
                result.actions = this.actions;
                var aNewEvents = [];
                for (var i = 0; i < this.events.length; i++) {
                    if (this.events[i].id == result.id) aNewEvents.push(result);
                    else aNewEvents.push(this.events[i]);
                }
                this.events = aNewEvents;
            });
        }
        // this.modal.open(this.modalContent, { size: 'lg' });
    }

    addEvent(): void {
        const dialogRef = this.dialog.open(VcmsCalendarEntryComponent, {
            data: {
                calendarEntryTitel: 'Neuer Eintrag',
                calendarEntryType: 'News',
                calendarEntryStartDate: new Date(),
                calendarEntryEndDate: new Date(),
                sAlexaSkillId: this.sAlexaSkillId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == undefined) return;

            console.log(`Dialog result: ${result}`);
            result.actions = this.actions;
            this.events = [...this.events, result];
        });
    }

    deleteEvent(oEventToDelete: CalendarEvent) {
        this.events = this.events.filter(event => event !== oEventToDelete);
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }
}

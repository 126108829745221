import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Auth } from 'aws-amplify';
import { PodcastContentType } from '../shared/PodcastContentType';
import { RadioContentType } from '../shared/RadioContentType';
import { SkillType } from '../shared/SkillType';
import {
  checkEmail,
  checkLogoType,
  checkAlexaSkillId
} from '../shared/Validations';
import * as ErrorMessages from '../shared/ErrorMessages';
import * as Toolkit from '../shared/Toolkit';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppsyncService } from '../services/appsync.service';
import { S3Service } from '../services/s3.service';

@Component({
  selector: 'app-vcms-skill',
  templateUrl: './vcms-skill.component.html',
  styleUrls: ['./vcms-skill.component.scss']
})
export class VcmsSkillComponent implements OnInit {
  formGroup: FormGroup;

  bCreateMode: boolean;
  sCurrentId: string;
  sLogoLargeUrl: string;
  sLogoSmallUrl: string;
  sBackgroundImageLargeUrl: string;
  sBackgroundImageSmallUrl: string;
  busy: Promise<any>;
  busymessage: string;
  oSkill: SkillType = {};
  oPodcastContent: PodcastContentType = {};
  oRadioContent: RadioContentType = {};

  getFilePath = Toolkit.getFilePath;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appsyncService: AppsyncService,
    private s3Service: S3Service,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {

    this.initEmptyData();
    this.route.params.subscribe((params = {}) => {
      if (params.id === undefined) {
        this.bCreateMode = true;
        this.initNewData();
      } else {
        this.bCreateMode = false;

        this.sCurrentId = params.id;
        this.getData();
      }
    });
  }

  ngOnInit() {
    this.busymessage = 'Speichern...';
  }

  /**
   * Initialization of variables
   */

  async initGeneral(oSkill) {
    try {
      let aOwnerForm;
      if (this.bCreateMode) {
        aOwnerForm = [null, [Validators.required, checkEmail]];
      } else {
        const oUser = await Auth.currentAuthenticatedUser();
        // tslint:disable-next-line: max-line-length
        aOwnerForm = (oUser.signInUserSession.idToken.payload['cognito:groups'] && oUser.signInUserSession.idToken.payload['cognito:groups'].includes('Admin')) ?
          [oSkill.sOwner, [Validators.required, checkEmail]] : [{ value: oSkill.sOwner, disabled: true }];
      }

      let aAlexaSkillNameForm;
      if (this.bCreateMode) {
        aAlexaSkillNameForm = [oSkill.sAlexaSkillName, [Validators.required], this.checkIsAlexaSkillNameUsed.bind(this)];
      } else {
        aAlexaSkillNameForm = [{ value: oSkill.sAlexaSkillName, disabled: true }];
      }

      let aSkillTypeForm;
      if (this.bCreateMode) {
        aSkillTypeForm = [oSkill.sSkillType, [Validators.required]];
      } else {
        aSkillTypeForm = [{ value: oSkill.sSkillType, disabled: true }];
      }

      this.formGroup = this.formBuilder.group({
        id: [oSkill.id],
        sAlexaSkillName: aAlexaSkillNameForm,
        sInvocationName: [oSkill.sInvocationName, [Validators.required]],
        sAlexaSkillId: [oSkill.sAlexaSkillId, [Validators.required, checkAlexaSkillId]],
        sSkillType: aSkillTypeForm,
        sLogoLargePath: [oSkill.sLogoLargePath],
        oLogoLargeInput: this.bCreateMode ?
          [null, [Validators.required, checkLogoType.bind(this)]] :
          [null, [checkLogoType.bind(this)]],
        sLogoSmallPath: [oSkill.sLogoSmallPath],
        oLogoSmallInput: this.bCreateMode ?
          [null, [Validators.required, checkLogoType.bind(this)]] :
          [null, [checkLogoType.bind(this)]],
        sBackgroundImageLargePath: [oSkill.sBackgroundImageLargePath],
        oBackgroundImageLargeInput: this.bCreateMode ? [null, [Validators.required]] : [null],
        sBackgroundImageSmallPath: [oSkill.sBackgroundImageSmallPath],
        oBackgroundImageSmallInput: this.bCreateMode ? [null, [Validators.required]] : [null],
        sOwner: aOwnerForm
      });

      this.setFileUrl(null, 'logo', 'large');
      this.setFileUrl(null, 'logo', 'small');
      this.setFileUrl(null, 'background', 'large');
      this.setFileUrl(null, 'background', 'small');
    } catch (oError) { }
  }


  /**
   * Special Validations for formfields
   */

  // Validation for Podcast Title
  checkIsAlexaSkillNameUsed(oControl) {
    return new Observable(observer => {
      this.appsyncService.isAlexaSkillNameUsed(oControl.value)
        .then((bAlexaSkillNameUsed) => {
          observer.next(bAlexaSkillNameUsed ? { alreadyUsed: true } : null);
          observer.complete();
        })
        .catch((oError) => {
          observer.next({ error: true });
          observer.complete();
        });
    });
  }

  /**
   * Other methods
   */

  getErrorMessage(form, type) {
    return ErrorMessages[type](this.formGroup.get(form));
  }

  // Set URL  for either selected or uploaded file
  async setFileUrl(oEvent, sGroup, sSize) {
    if (oEvent && oEvent.target.files && oEvent.target.files[0]) {
      const oReader = new FileReader();

      oReader.readAsDataURL(oEvent.target.files[0]);

      oReader.onload = (oObject) => {
        sGroup === 'logo' ?
          sSize === 'large' ?
            // @ts-ignore
            this.sLogoLargeUrl = oObject.target.result :
            // @ts-ignore
            this.sLogoSmallUrl = oObject.target.result :
          sSize === 'large' ?
            // @ts-ignore
            this.sBackgroundImageLargeUrl = oObject.target.result :
            // @ts-ignore
            this.sBackgroundImageSmallUrl = oObject.target.result;
      };
    } else {
      sGroup === 'logo' ?
        sSize === 'large' ?
          this.formGroup.controls.sLogoLargePath.value ?
            this.sLogoLargeUrl = await this.s3Service.getFileUrl(this.formGroup.controls.sLogoLargePath.value) :
            this.sLogoLargeUrl = '' :
          this.formGroup.controls.sLogoSmallPath.value ?
            this.sLogoSmallUrl = await this.s3Service.getFileUrl(this.formGroup.controls.sLogoSmallPath.value) :
            this.sLogoSmallUrl = '' :
        sSize === 'large' ?
          this.formGroup.controls.sBackgroundImageLargePath.value ?
            this.sBackgroundImageLargeUrl = await this.s3Service.getFileUrl(this.formGroup.controls.sBackgroundImageLargePath.value) :
            this.sBackgroundImageLargeUrl = '' :
          this.formGroup.controls.sBackgroundImageSmallPath.value ?
            this.sBackgroundImageSmallUrl = await this.s3Service.getFileUrl(this.formGroup.controls.sBackgroundImageSmallPath.value) :
            this.sBackgroundImageSmallUrl = '';
    }
  }

  // Map value of button to variable (checked or not checked)
  // changeDeselectButton(oEvent, sButton) {
  //   this[sButton] = oEvent.checked;
  // }

  // Either create or update Skill
  submitSkill() {
    try {
      this.busy = new Promise(async (resolve) => {
        if (this.bCreateMode) {
          await this.createSkillAndContent(this.formGroup.getRawValue());
        } else {
          await this.updateSkillAndContent(this.formGroup.getRawValue());
        }
      });
    } catch (error) {
      this.snackBar.open('Es konnte nicht erfolgreich gespeichert werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

  // Upload all files and create content
  async createSkillAndContent(oData) {
    console.log('Data', oData);
    const mFilesToUpload = new Map();

    mFilesToUpload.set('BackgroundImageLarge', oData.oBackgroundImageLargeInput);
    mFilesToUpload.set('BackgroundImageSmall', oData.oBackgroundImageSmallInput);

    mFilesToUpload.set('LogoLarge', oData.oLogoLargeInput);
    mFilesToUpload.set('LogoSmall', oData.oLogoSmallInput);

    mFilesToUpload.forEach(async (oInput, sFileName) => {
      this.busymessage = `Uploading ${oInput.fileNames}`;
      const sKey = await this.s3Service.uploadFile(oInput.files[0], `${oData.sAlexaSkillName}/${oInput.fileNames}`);

      delete oData[`o${sFileName}Input`];
      oData[`s${sFileName}Path`] = sKey;

      mFilesToUpload.delete(sFileName);

      if (mFilesToUpload.size === 0) {
        console.log('All Files uploaded');

        const oSkill = await this.appsyncService.createSkill(oData);

        if (oData.sSkillType === 'Podcast') {
          this.oPodcastContent.id = oSkill.id;
          this.oPodcastContent.sAlexaSkillId = oSkill.sAlexaSkillId;
          this.oPodcastContent.sOwner = oSkill.sOwner;
          this.oPodcastContent.bNeedEpisodePrefix = true;

          // @ts-ignore
          const oPodcastContent = await this.appsyncService.createPodcastContent(this.oPodcastContent);

          this.router.navigateByUrl(`/skill/podcast/${oPodcastContent.id}`);
        } else {
          this.oRadioContent.id = oSkill.id;
          this.oRadioContent.sAlexaSkillId = oSkill.sAlexaSkillId;
          this.oRadioContent.sOwner = oSkill.sOwner;

          // @ts-ignore
          const oRadioContent = await this.appsyncService.createRadioContent(this.oRadioContent);

          this.router.navigateByUrl(`/skill/radio/${oRadioContent.id}`);
        }
      }
    });
  }

  // Upload all new files and update content
  async updateSkillAndContent(oData) {
    console.log('Data', oData);
    // oData.podcastTitle = this.sCurrentAlexaSkillName;
    const mFilesToUpload = new Map();

    if (oData.oBackgroundImageLargeInput && oData.oBackgroundImageLargeInput !== null) {
      mFilesToUpload.set('BackgroundImageLarge', oData.oBackgroundImageLargeInput);
    } else {
      delete oData.oBackgroundImageLargeInput;
    }

    if (oData.oBackgroundImageSmallInput && oData.oBackgroundImageSmallInput !== null) {
      mFilesToUpload.set('BackgroundImageSmall', oData.oBackgroundImageSmallInput);
    } else {
      delete oData.oBackgroundImageSmallInput;
    }

    if (oData.oLogoLargeInput && oData.oLogoLargeInput !== null) {
      mFilesToUpload.set('LogoLarge', oData.oLogoLargeInput);
    } else {
      delete oData.oLogoLargeInput;
    }

    if (oData.oLogoSmallInput && oData.oLogoSmallInput !== null) {
      mFilesToUpload.set('LogoSmall', oData.oLogoSmallInput);
    } else {
      delete oData.oLogoSmallInput;
    }

    const fUpdate = async oInput => {
      await this.appsyncService.updateSkill(oInput);

      if (oData.sSkillType === 'Podcast') {
        this.oPodcastContent.id = oInput.id;
        this.oPodcastContent.sAlexaSkillId = oInput.sAlexaSkillId;
        this.oPodcastContent.sOwner = oInput.sOwner;
        delete this.oPodcastContent.createdAt;
        delete this.oPodcastContent.updatedAt;

        // @ts-ignore
        await this.appsyncService.updatePodcastContent(this.oPodcastContent);
      } else {
        this.oRadioContent.id = oInput.id;
        this.oRadioContent.sAlexaSkillId = oInput.sAlexaSkillId;
        this.oRadioContent.sOwner = oInput.sOwner;
        delete this.oRadioContent.createdAt;
        delete this.oRadioContent.updatedAt;

        // @ts-ignore
        await this.appsyncService.updateRadioContent(this.oRadioContent);
      }
      this.router.navigate(['/reload'], { queryParams: { path: `/skill/${oInput.id}` } });
    };

    if (mFilesToUpload.size > 0) {
      mFilesToUpload.forEach(async (oInput, sFileName) => {
        this.busymessage = `Uploading ${oInput.fileNames}`;
        const sKey = await this.s3Service.uploadFile(oInput.files[0], `${oData.sAlexaSkillName}/${oInput.fileNames}`);

        delete oData[`o${sFileName}Input`];
        oData[`s${sFileName}Path`] = sKey;

        mFilesToUpload.delete(sFileName);

        if (mFilesToUpload.size === 0) {
          console.log('All Files uploaded');

          await fUpdate(oData);
        }
      });
    } else {
      await fUpdate(oData);
    }
  }

  // Init the skill
  initEmptyData() {
    try {
      this.oPodcastContent = this.appsyncService.emptyNewPodcastContent();
      this.oRadioContent = this.appsyncService.emptyNewRadioContent();
      this.oSkill = this.appsyncService.emptyNewSkill();
      this.initGeneral(this.oSkill);
    } catch (oError) { }
  }

  initNewData() {
    try {
      this.oPodcastContent = this.appsyncService.initNewPodcastContent(this.oPodcastContent);
      this.oRadioContent = this.appsyncService.initNewRadioContent(this.oRadioContent);
      this.oSkill = this.appsyncService.initNewSkill(this.oSkill);
      this.initGeneral(this.oSkill);
    } catch (oError) { }
  }

  // Get the skill with ID from URL
  async getData() {
    try {
      this.oSkill = await this.appsyncService.getSkill(this.sCurrentId);
      if (this.oSkill.sSkillType === 'Podcast') {
        this.oPodcastContent = await this.appsyncService.getPodcastContent(this.sCurrentId);
      } else {
        this.oRadioContent = await this.appsyncService.getRadioContent(this.sCurrentId);
      }
      this.initGeneral(this.oSkill);
    } catch (oError) {
      this.snackBar.open('Skill konnte nicht geladen werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }
}

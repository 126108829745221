import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PodcastContentType } from '../shared/PodcastContentType';
import { SkillType } from '../shared/SkillType';
import {
  checkUrl,
  checkEmail,
  checkAudioType
} from '../shared/Validations';
import * as ErrorMessages from '../shared/ErrorMessages';
import * as Toolkit from '../shared/Toolkit';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppsyncService } from '../services/appsync.service';
import { S3Service } from '../services/s3.service';

@Component({
  selector: 'app-vcms-podcast-content',
  templateUrl: './vcms-podcast-content.component.html',
  styleUrls: ['./vcms-podcast-content.component.scss']
})
export class VcmsPodcastContentComponent implements OnInit {
  formGroup: FormGroup;

  sCurrentId: string;
  busy: Promise<any>;
  busymessage: string;
  oSkill: SkillType = {};
  oPodcastContent: PodcastContentType = {};

  getFilePath = Toolkit.getFilePath;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appsyncService: AppsyncService,
    private s3Service: S3Service,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {

    this.initEmptyData();
    this.route.params.subscribe((params = {}) => {
      this.sCurrentId = params.id;
      this.getData();
    });
  }

  ngOnInit() {
    this.busymessage = 'Speichern...';
  }

  /**
   * Initialization of variables
   */

  async initGeneral(oPodcastContent) {
    try {
      this.formGroup = this.formBuilder.group({
        id: [oPodcastContent.id],
        sPodcastRssUrl: [oPodcastContent.sPodcastRssUrl, [Validators.required, checkUrl]],
        sContactEmail: [oPodcastContent.sContactEmail, [Validators.required, checkEmail]],
        bNeedEpisodePrefix: [oPodcastContent.bNeedEpisodePrefix, [Validators.required]],
        sFirstLaunchStartText: [oPodcastContent.sFirstLaunchStartText],
        sFirstLaunchAudioPath: [oPodcastContent.sFirstLaunchAudioPath],
        sFirstLaunchAudioInput: [null, [checkAudioType.bind(this)]],
        sLaunchStartText: [oPodcastContent.sLaunchStartText],
        sLaunchStartAudioPath: [oPodcastContent.sLaunchStartAudioPath],
        sLaunchStartAudioInput: [null, [checkAudioType.bind(this)]],
        sLaunchStartRepromtText: [oPodcastContent.sLaunchStartRepromtText],
        sLaunchStartRepromtAudioPath: [oPodcastContent.sLaunchStartRepromtAudioPath],
        sLaunchStartRepromtAudioInput: [null, [checkAudioType.bind(this)]],

        sLaunchResumeText: [oPodcastContent.sLaunchResumeText],
        sLaunchResumeAudioPath: [oPodcastContent.sLaunchResumeAudioPath],
        sLaunchResumeAudioInput: [null, [checkAudioType.bind(this)]],
        sLaunchResumeRepromtText: [oPodcastContent.sLaunchResumeRepromtText],
        sLaunchResumeRepromtAudioPath: [oPodcastContent.sLaunchResumeRepromtAudioPath],
        sLaunchResumeRepromtAudioInput: [null, [checkAudioType.bind(this)]],

        sNewOrResumeText: [oPodcastContent.sNewOrResumeText],
        sNewOrResumeAudioPath: [oPodcastContent.sNewOrResumeAudioPath],
        sNewOrResumeAudioInput: [null, [checkAudioType.bind(this)]],
        sNewOrResumeRepromtText: [oPodcastContent.sNewOrResumeRepromtText],
        sNewOrResumeRepromtAudioPath: [oPodcastContent.sNewOrResumeRepromtAudioPath],
        sNewOrResumeRepromtAudioInput: [null, [checkAudioType.bind(this)]],

        sStopText: [oPodcastContent.sStopText],
        sStopAudioPath: [oPodcastContent.sStopAudioPath],
        sStopAudioInput: [null, [checkAudioType.bind(this)]],
        sEndOfPlaylistAudioPath: [oPodcastContent.sEndOfPlaylistAudioPath],
        sEndOfPlaylistAudioInput: [null, [checkAudioType.bind(this)]],
        sEndOfPlaylistExtendedAudioPath: [oPodcastContent.sEndOfPlaylistExtendedAudioPath],
        sEndOfPlaylistExtendedAudioInput: [null, [checkAudioType.bind(this)]],
        sAskForNotificationPermissionAudioPath: [oPodcastContent.sAskForNotificationPermissionAudioPath],
        sAskForNotificationPermissionAudioInput: [null, [checkAudioType.bind(this)]],
        sNotificationPermissionConfirmedAudioPath: [oPodcastContent.sNotificationPermissionConfirmedAudioPath],
        sNotificationPermissionConfirmedAudioInput: [null, [checkAudioType.bind(this)]],
        sMessageReceivedAudioPath: [oPodcastContent.sMessageReceivedAudioPath],
        sMessageReceivedAudioInput: [null, [checkAudioType.bind(this)]],
        sAskForInitialGuidingAudioPath: [oPodcastContent.sAskForInitialGuidingAudioPath],
        sAskForInitialGuidingAudioInput: [null, [checkAudioType.bind(this)]],
        sInitialGuidingAudioPath: [oPodcastContent.sInitialGuidingAudioPath],
        sInitialGuidingAudioInput: [null, [checkAudioType.bind(this)]],
        sNoInitialGuidingAudioPath: [oPodcastContent.sNoInitialGuidingAudioPath],
        sNoInitialGuidingAudioInput: [null, [checkAudioType.bind(this)]],
        sAskForMoreActionsHelpAudioPath: [oPodcastContent.sAskForMoreActionsHelpAudioPath],
        sAskForMoreActionsHelpAudioInput: [null, [checkAudioType.bind(this)]],
        sEpisodeListPrefixAudioPath: [oPodcastContent.sEpisodeListPrefixAudioPath],
        sEpisodeListPrefixAudioInput: [null, [checkAudioType.bind(this)]],
        sEpisodeSearchListPrefixAudioPath: [oPodcastContent.sEpisodeSearchListPrefixAudioPath],
        sEpisodeSearchListPrefixAudioInput: [null, [checkAudioType.bind(this)]],
        sAskForEpisodeListAudioPath: [oPodcastContent.sAskForEpisodeListAudioPath],
        sAskForEpisodeListAudioInput: [null, [checkAudioType.bind(this)]],
      });
    } catch (oError) { }
  }

  /**
   * Setter for switch
   */

  // Set value for bNeedEpisodePrefix
  onSwitchChange(oEvent) {
    this.formGroup.controls.bNeedEpisodePrefix.setValue(oEvent.checked);
  }


  /**
   * Other methods
   */

  getErrorMessage(form, type) {
    return ErrorMessages[type](this.formGroup.get(form));
  }

  // Map value of button to variable (checked or not checked)
  // changeDeselectButton(oEvent, sButton) {
  //   this[sButton] = oEvent.checked;
  // }

  // Update Podcast Content
  updatePodcastContent() {
    try {
      this.busy = new Promise(async (resolve) => {
        const oData = this.formGroup.value;

        const mFilesToUpload = new Map();

        const aInputs = [
          'sFirstLaunchAudio',
          'sLaunchStartAudio',
          'sLaunchStartRepromtAudio',
          'sLaunchResumeAudio',
          'sLaunchResumeRepromtAudio',

          'sNewOrResumeAudio',
          'sNewOrResumeRepromtAudio',
          'sStopAudio',
          'sEndOfPlaylistAudio',
          'sEndOfPlaylistExtendedAudio',
          'sAskForNotificationPermissionAudio',
          'sNotificationPermissionConfirmedAudio',
          'sMessageReceivedAudio',
          'sAskForInitialGuidingAudio',
          'sInitialGuidingAudio',
          'sNoInitialGuidingAudio',
          'sAskForMoreActionsHelpAudio',
          'sEpisodeListPrefixAudio',
          'sEpisodeSearchListPrefixAudio',
          'sAskForEpisodeListAudio'
        ];

        aInputs.forEach(sInput => {
          if (oData[sInput + 'Input'] && oData[sInput + 'Input'] !== null) {
            mFilesToUpload.set(sInput, oData[sInput + 'Input']);
          } else {
            delete oData[sInput + 'Input'];
          }
        });

        const fUpdate = async oInput => {
          await this.appsyncService.updatePodcastContent(oInput);
          this.router.navigate(['/reload'], { queryParams: { path: `/skill/podcast/${oInput.id}` } });
        };

        if (mFilesToUpload.size > 0) {
          mFilesToUpload.forEach(async (oInput, sFileName) => {
            this.busymessage = `Uploading ${oInput.fileNames}`;
            const sKey = await this.s3Service.uploadFile(oInput.files[0], `${this.oSkill.sAlexaSkillName}/${oInput.fileNames}`);
            this.busymessage = `Converting ${oInput.fileNames}`;
            await this.appsyncService.MP3Conversion(this.oSkill.sAlexaSkillName, oInput.fileNames);

            delete oData[`${sFileName}Input`];
            oData[`${sFileName}Path`] = sKey;

            mFilesToUpload.delete(sFileName);

            if (mFilesToUpload.size === 0) {
              console.log('All Files uploaded');

              // await this.convertMP3s(oData.podcastTitle, mFilesToUpload);
              console.log('All Files converted');

              await fUpdate(oData);
            }
          });
        } else {
          await fUpdate(oData);
        }
      });
    } catch (error) {
      this.snackBar.open('Es konnte nicht erfolgreich gespeichert werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

  // Init the skill
  async initEmptyData() {
    try {
      this.oPodcastContent = await this.appsyncService.emptyNewPodcastContent();
      this.oSkill = await this.appsyncService.emptyNewSkill();
      this.initGeneral(this.oPodcastContent);
    } catch (oError) { }
  }

  // Get the podcast with ID from URL
  async getData() {
    try {
      this.oPodcastContent = await this.appsyncService.getPodcastContent(this.sCurrentId);
      this.oSkill = await this.appsyncService.getSkill(this.sCurrentId);
      this.initGeneral(this.oPodcastContent);
    } catch (oError) {
      this.snackBar.open('Podcast konnte nicht geladen werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

  clearFile(sFormControl) {
    this.formGroup.get(sFormControl).reset();
  }
}

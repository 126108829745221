import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdkTableModule } from '@angular/cdk/table';
import { ChartsModule } from 'ng2-charts';
import { NgBusyModule } from 'ng-busy';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { PodcastListComponent, PodcastListPopupComponent } from './podcast-list/podcast-list.component';
import { PodcastCreateComponent } from './podcast-create/podcast-create.component';

import { AppsyncService } from './services/appsync.service';
import { S3Service } from './services/s3.service';
import { ReloadComponentComponent } from './reload-component/reload-component.component';
import { ErrorComponent } from './error/error.component';
import { environment } from './../environments/environment';

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PodcastAnalyticsComponent } from './podcast-analytics/podcast-analytics.component';
import { VcmsCalendarComponent } from './vcms-calendar/vcms-calendar.component';

import { FlatpickrModule } from 'angularx-flatpickr';

import { VcmsCalendarEntryComponent } from './vcms-calendar-entry/vcms-calendar-entry.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { VcmsSkillComponent } from './vcms-skill/vcms-skill.component';
import { VcmsPodcastContentComponent } from './vcms-podcast-content/vcms-podcast-content.component';
import { VcmsSkillListComponent } from './vcms-skill-list/vcms-skill-list.component';
import { VcmsAudioInputComponent } from './vcms-audio-input/vcms-audio-input.component';
import {
  DragDropModule
} from '@angular/cdk/drag-drop';

import { SharedModule } from './shared/shared.module';
import { VcmsActionComponent } from './vcms-action/vcms-action.component';
import { VcmsPodcastActionContentComponent } from './vcms-podcast-action-content/vcms-podcast-action-content.component';
import { VcmsRadioContentComponent } from './vcms-radio-content/vcms-radio-content.component';
import { FormsModule } from '@angular/forms';
import { VcmsDialogPopupComponent } from './vcms-dialog-popup/vcms-dialog-popup.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    // tslint:disable-next-line: max-line-length
    domain: environment.cookieDomain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'info',
  content: {
    href: 'https://wakeword.de/datenschutz/',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    PodcastListComponent,
    PodcastCreateComponent,
    ReloadComponentComponent,
    ErrorComponent,
    PodcastListPopupComponent,
    PodcastAnalyticsComponent,
    VcmsCalendarComponent,
    VcmsCalendarEntryComponent,
    VcmsSkillComponent,
    VcmsPodcastContentComponent,
    VcmsSkillListComponent,
    VcmsAudioInputComponent,
    VcmsActionComponent,
    VcmsPodcastActionContentComponent,
    VcmsRadioContentComponent,
    VcmsDialogPopupComponent
  ],
  imports: [
    // Our shared stuff
    SharedModule,

    MatDatepickerModule,
    MatNativeDateModule,
    BrowserModule,
    AppRoutingModule,
    AmplifyAngularModule,
    BrowserAnimationsModule,
    CdkTableModule,
    ChartsModule,
    FormsModule,
    MatBadgeModule,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MaterialFileInputModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    DragDropModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    // TranslateModule.forRoot()
    HttpClientModule,
    NgBusyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [
    AmplifyService,
    AppsyncService,
    S3Service,
    MatNativeDateModule,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      }
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    PodcastListPopupComponent,
    VcmsCalendarEntryComponent,
    PodcastListPopupComponent,
    VcmsDialogPopupComponent
  ]
})
export class AppModule { }

export class PodcastType {
    constructor(
        public owner?: string,
        public id?: string,
        public podcastTitle?: string,
        public invocationName?: string,
        public alexaSkillId?: string,
        public podcastRssUrl?: string,
        public email?: string,
        public startPlayInitText?: string,
        public startPlayInitAudioPath?: string,
        public startPlayNewText?: string,
        public startPlayNewAudioPath?: string,
        public startPlayRepromptText?: string,
        public startPlayRepromptAudioPath?: string,
        public startResumeText?: string,
        public startResumeAudioPath?: string,
        public startResumeRepromptText?: string,
        public startResumeRepromptAudioPath?: string,
        public finishText?: string,
        public finishAudioPath?: string,
        public endOfPlaylistAudioPath?: string,
        public endOfPlaylistExtendedAudioPath?: string,
        public notificationPermissionAudioPath?: string,
        public notificationActivatedAudioPath?: string,
        public messageReceivedAudioPath?: string,
        public askForGuidingAudioPath?: string,
        public guidingAudioPath?: string,
        public noGuidingAudioPath?: string,
        public askForMoreActionsAudioPath?: string,
        public logoLargePath?: string,
        public logoSmallPath?: string,
        public backgroundImageLargePath?: string,
        public backgroundImageSmallPath?: string,
        public createdAt?: string,
        public updatedAt?: string
    ) { }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeaderComponent } from './vcms-page-header/page-header.component';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule],
    exports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, PageHeaderComponent],
    declarations: [PageHeaderComponent]
})
export class SharedModule {}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vcms-dialog-popup',
  templateUrl: './vcms-dialog-popup.component.html',
  styleUrls: ['./vcms-dialog-popup.component.scss']
})
export class VcmsDialogPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<VcmsDialogPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  usernameAttributes = 'email';

  signUpConfig = {
    header: 'Willkommen auf der Alexa Podcast Konsole!',
    defaultCountryCode: '49',
    hideAllDefaults: true,
    signUpFields: [
      {
        label: 'E-Mail',
        key: 'username',
        required: true,
        displayOrder: 1,
        type: 'string',
      },
      // {
      //   label: 'Email',
      //   key: 'email',
      //   required: true,
      //   displayOrder: 2,
      //   type: 'email',
      // },
      {
        label: 'Password',
        key: 'password',
        required: true,
        displayOrder: 3,
        type: 'password',
      },
      {
        label: 'Name',
        key: 'name',
        required: true,
        displayOrder: 3,
        type: 'string',
      }
    ]
  };
}

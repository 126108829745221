import { Injectable } from '@angular/core';
import Amplify from 'aws-amplify';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { PodcastType } from './../shared/PodcastType';
import { SkillType } from './../shared/SkillType';
import { PodcastContentType } from './../shared/PodcastContentType';
import { RadioContentType, RadioEntryType } from './../shared/RadioContentType';
import { ActionType } from './../shared/ActionType';
import { PodcastActionContentType } from './../shared/PodcastActionContentType';

import {
    CreatePodcastInput,
    UpdatePodcastInput,
    CreateSkillInput,
    CreatePodcastContentInput,
    UpdateSkillInput,
    UpdatePodcastContentInput,
    CreateCalendarEntryInput,
    UpdateCalendarEntryInput,
    CreateActionInput,
    UpdateActionInput,
    CreatePodcastActionContentInput,
    UpdatePodcastActionContentInput,
    CreateRadioContentInput,
    UpdateRadioContentInput,
    SmapiCreateCatalogVersionMutationVariables
} from '../../API';
import {
    listPodcasts,
    getPodcast,
    getPodcastAnalytics,
    listPodcastAnalyticsEpisodes,
    listPodcastAnalyticsUsers,
    triggerMp3Conversion,
    getSkill,
    getPodcastContent,
    listSkills,
    listPodcastContents,
    listCalendarEntrys,
    getAction,
    listActions,
    getPodcastActionContent,
    listPodcastActionContents,
    getRadioContent,
    listRadioContents
} from '../../graphql/queries';
import {
    createPodcast,
    updatePodcast,
    deletePodcast,
    createSkill,
    createPodcastContent,
    updateSkill,
    updatePodcastContent,
    deleteSkill,
    deletePodcastContent,
    createCalendarEntry,
    updateCalendarEntry,
    deleteCalendarEntry,
    createAction,
    updateAction,
    deleteAction,
    createPodcastActionContent,
    updatePodcastActionContent,
    deletePodcastActionContent,
    createRadioContent,
    updateRadioContent,
    deleteRadioContent,
    smapiCreateCatalogVersion
} from '../../graphql/mutations';
import { } from '../../graphql/subscriptions';
import {
    SkillList,
    ActionList
} from './appsync.model';
// import {  } from '../../graphql/changed';

@Injectable({
    providedIn: 'root'
})
export class AppsyncService {
    constructor() {
        Amplify.configure({
            API: {
                graphql_headers: async () => ({
                    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
                })
            }
        });
    }

    /**
     * Skill/Podcast-Content Type Queries/Mutations
     */

    // V1 (old version)
    async createPodcast(oCreatePodcastInput: CreatePodcastInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oPodcast = (await API.graphql(graphqlOperation(createPodcast, { input: this.cleanObject(oCreatePodcastInput) }))).data
                .createPodcast;

            console.log('Success Mutation createPodcast', oPodcast);
            return oPodcast;
        } catch (oError) {
            console.log('Error Mutation createPodcast', oError);
            throw oError;
        }
    }

    async createSkill(oCreateSkillInput: CreateSkillInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oSkill = (await API.graphql(graphqlOperation(createSkill, { input: this.cleanObject(oCreateSkillInput) }))).data
                .createSkill;

            console.log('Success Mutation createSkill', oSkill);
            return oSkill;
        } catch (oError) {
            console.log('Error Mutation createSkill', oError);
            throw oError;
        }
    }

    async createAction(oCreateActionInput: CreateActionInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oAction = (await API.graphql(graphqlOperation(createAction, { input: this.cleanObject(oCreateActionInput) }))).data
                .createAction;

            console.log('Success Mutation createAction', oAction);
            return oAction;
        } catch (oError) {
            console.log('Error Mutation createAction', oError);
            throw oError;
        }
    }

    buildDateStringWithoutTime(mydate: Date) {
        const sDay = ('0' + mydate.getDate()).slice(-2); // Consider Leading Zeros
        const sMonth = ('0' + (mydate.getMonth() + 1)).slice(-2); // Consider Leading Zeros
        return `${mydate.getFullYear()}-${sMonth}-${sDay}T00:00:00.000Z`;
    }

    async createCalendarEntry(oCreateCalendarEntryInput: CreateCalendarEntryInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            const tmpStartDateInCurrentTimeZone = new Date(oCreateCalendarEntryInput.sCalendarEntryStartDate);
            const tmpEndDateInCurrentTimeZone = new Date(oCreateCalendarEntryInput.sCalendarEntryEndDate);

            oCreateCalendarEntryInput.sCalendarEntryStartDate = this.buildDateStringWithoutTime(tmpStartDateInCurrentTimeZone);
            oCreateCalendarEntryInput.sCalendarEntryEndDate = this.buildDateStringWithoutTime(tmpEndDateInCurrentTimeZone);

            // prettier-ignore
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oCalendarEntry = (await API.graphql(graphqlOperation(createCalendarEntry, { input: this.cleanObject(oCreateCalendarEntryInput) }))).data.createCalendarEntry;

            console.log('Success Mutation createCalendarEntry', oCalendarEntry);
            return oCalendarEntry;
        } catch (oError) {
            console.log('Error Mutation createCalendarEntry', oError);
            throw oError;
        }
    }

    async updateCalendarEntry(oUpdateCalendarEntryInput: UpdateCalendarEntryInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            const tmpStartDateInCurrentTimeZone = new Date(oUpdateCalendarEntryInput.sCalendarEntryStartDate);
            const tmpEndDateInCurrentTimeZone = new Date(oUpdateCalendarEntryInput.sCalendarEntryEndDate);

            oUpdateCalendarEntryInput.sCalendarEntryStartDate = this.buildDateStringWithoutTime(tmpStartDateInCurrentTimeZone);
            oUpdateCalendarEntryInput.sCalendarEntryEndDate = this.buildDateStringWithoutTime(tmpEndDateInCurrentTimeZone);

            // prettier-ignore
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oCalendarEntry = (await API.graphql(graphqlOperation(updateCalendarEntry, { input: this.cleanObject(oUpdateCalendarEntryInput) }))).data.createCalendarEntry;

            console.log('Success Mutation createCalendarEntry', oCalendarEntry);
            return oCalendarEntry;
        } catch (oError) {
            console.log('Error Mutation createCalendarEntry', oError);
            throw oError;
        }
    }

    async deleteCalendarEntry(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oCalendarEntry = (await API.graphql(graphqlOperation(deleteCalendarEntry, { input: { id: sId } }))).data
                .deleteCalendarEntry;

            console.log('Success Mutation deletePodcast', oCalendarEntry);
            return oCalendarEntry;
        } catch (oError) {
            console.log('Error Mutation deletePodcast', oError);
            throw oError;
        }
    }

    async getCalendarEntries(sAlexaSkillId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const aCalendarEntries = (await API.graphql(graphqlOperation(listCalendarEntrys, { limit: 1000 }))).data.listCalendarEntrys
                .items;

            console.log('Success Query listCalendarEntrys', aCalendarEntries);
            return aCalendarEntries;
        } catch (oError) {
            console.log('Error Query listCalendarEntrys', oError);
            throw oError;
        }
    }

    async createPodcastContent(oCreatePodcastContentInput: CreatePodcastContentInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            // prettier-ignore
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oPodcastContent = (await API.graphql(graphqlOperation(createPodcastContent, { input: this.cleanObject(oCreatePodcastContentInput) }))).data.createPodcastContent;

            console.log('Success Mutation createPodcastContent', oPodcastContent);
            return oPodcastContent;
        } catch (oError) {
            console.log('Error Mutation createPodcastContent', oError);
            throw oError;
        }
    }

    async createRadioContent(oCreateRadioContentInput: CreateRadioContentInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            // prettier-ignore
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oRadioContent = (await API.graphql(graphqlOperation(createRadioContent, { input: this.cleanObject(oCreateRadioContentInput) }))).data.createRadioContent;

            console.log('Success Mutation createRadioContent', oRadioContent);
            return oRadioContent;
        } catch (oError) {
            console.log('Error Mutation createRadioContent', oError);
            throw oError;
        }
    }

    async createPodcastActionContent(oCreatePodcastActionContentInput: CreatePodcastActionContentInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            // prettier-ignore
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oPodcastActionContent = (await API.graphql(graphqlOperation(createPodcastActionContent, { input: this.cleanObject(oCreatePodcastActionContentInput) }))).data.createPodcastActionContent;

            console.log('Success Mutation createPodcastActionContent', oPodcastActionContent);
            return oPodcastActionContent;
        } catch (oError) {
            console.log('Error Mutation createPodcastActionContent', oError);
            throw oError;
        }
    }

    // V1 (old version)
    async updatePodcast(oQuestionInput: UpdatePodcastInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oPodcast = (await API.graphql(graphqlOperation(updatePodcast, { input: this.cleanObject(oQuestionInput) }))).data
                .updatePodcast;

            console.log('Success Mutation updatePodcast', oPodcast);
            return oPodcast;
        } catch (oError) {
            console.log('Error Mutation updatePodcast', oError);
            throw oError;
        }
    }

    async updateSkill(oUpdateSkillInput: UpdateSkillInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oSkill = (await API.graphql(graphqlOperation(updateSkill, { input: this.cleanObject(oUpdateSkillInput) }))).data
                .updateSkill;

            console.log('Success Mutation updateSkill', oSkill);
            return oSkill;
        } catch (oError) {
            console.log('Error Mutation updateSkill', oError);
            throw oError;
        }
    }

    async updateAction(oUpdateActionInput: UpdateActionInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oAction = (await API.graphql(graphqlOperation(updateAction, { input: this.cleanObject(oUpdateActionInput) }))).data
                .updateAction;

            console.log('Success Mutation updateAction', oAction);
            return oAction;
        } catch (oError) {
            console.log('Error Mutation updateAction', oError);
            throw oError;
        }
    }

    async updatePodcastContent(oUpdatePodcastContentInput: UpdatePodcastContentInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            // prettier-ignore
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oPodcastContent = (await API.graphql(graphqlOperation(updatePodcastContent, { input: this.cleanObject(oUpdatePodcastContentInput) }))).data.updatePodcastContent;

            console.log('Success Mutation updatePodcastContent', oPodcastContent);
            return oPodcastContent;
        } catch (oError) {
            console.log('Error Mutation updatePodcastContent', oError);
            throw oError;
        }
    }

    async updateRadioContent(oUpdateRadioContentInput: UpdateRadioContentInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            // prettier-ignore
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oRadioContent = (await API.graphql(graphqlOperation(updateRadioContent, { input: this.cleanObject(oUpdateRadioContentInput) }))).data.updateRadioContent;

            console.log('Success Mutation updateRadioContent', oRadioContent);
            return oRadioContent;
        } catch (oError) {
            console.log('Error Mutation updateRadioContent', oError);
            throw oError;
        }
    }

    async updatePodcastActionContent(oUpdatePodcastActionContentInput: UpdatePodcastActionContentInput): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            // prettier-ignore
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oPodcastActionContent = (await API.graphql(graphqlOperation(updatePodcastActionContent, { input: this.cleanObject(oUpdatePodcastActionContentInput) }))).data.updatePodcastActionContent;

            console.log('Success Mutation updatePodcastActionContent', oPodcastActionContent);
            return oPodcastActionContent;
        } catch (oError) {
            console.log('Error Mutation updatePodcastActionContent', oError);
            throw oError;
        }
    }

    // V1 (old version)
    async getPodcast(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oPodcast = (await API.graphql(graphqlOperation(getPodcast, { id: sId }))).data.getPodcast;

            console.log('Success Query getPodcast', oPodcast);
            return oPodcast;
        } catch (oError) {
            console.log('Error Query getPodcast', oError);
            throw oError;
        }
    }

    async getSkill(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oSkill = (await API.graphql(graphqlOperation(getSkill, { id: sId }))).data.getSkill;

            console.log('Success Query getSkill', oSkill);
            return oSkill;
        } catch (oError) {
            console.log('Error Query getSkill', oError);
            throw oError;
        }
    }

    async getAction(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oAction = (await API.graphql(graphqlOperation(getAction, { id: sId }))).data.getAction;

            console.log('Success Query getAction', oAction);
            return oAction;
        } catch (oError) {
            console.log('Error Query getAction', oError);
            throw oError;
        }
    }

    async getPodcastContent(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oPodcastContent = (await API.graphql(graphqlOperation(getPodcastContent, { id: sId }))).data.getPodcastContent;

            console.log('Success Query getPodcastContent', oPodcastContent);
            return oPodcastContent;
        } catch (oError) {
            console.log('Error Query getPodcastContent', oError);
            throw oError;
        }
    }

    async getRadioContent(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oRadioContent = (await API.graphql(graphqlOperation(getRadioContent, { id: sId }))).data.getRadioContent;

            console.log('Success Query getRadioContent', oRadioContent);
            return oRadioContent;
        } catch (oError) {
            console.log('Error Query getRadioContent', oError);
            throw oError;
        }
    }

    async getPodcastActionContent(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oPodcastActionContent = (await API.graphql(graphqlOperation(getPodcastActionContent, { id: sId }))).data.getPodcastActionContent;

            console.log('Success Query getPodcastActionContent', oPodcastActionContent);
            return oPodcastActionContent;
        } catch (oError) {
            console.log('Error Query getPodcastActionContent', oError);
            throw oError;
        }
    }

    // V1 (old version)
    async emptyNewPodcast(): Promise<any> {
        const oPodcast = new PodcastType();
        return oPodcast;
    }

    emptyNewSkill() {
        const oSkill = new SkillType();
        return oSkill;
    }

    emptyNewAction() {
        const oAction = new ActionType();
        return oAction;
    }

    emptyNewPodcastContent() {
        const oPodcastContent = new PodcastContentType();
        return oPodcastContent;
    }

    emptyNewRadioContent() {
        const oRadioContent = new RadioContentType();
        oRadioContent.aRadio = [];
        return oRadioContent;
    }

    emptyNewRadioEntry() {
        const oRadioEntry = new RadioEntryType();
        oRadioEntry.aSynonyms = [];
        return oRadioEntry;
    }

    emptyNewPodcastActionContent() {
        const oPodcastActionContent = new PodcastActionContentType();
        return oPodcastActionContent;
    }

    // V1 (old version)
    async initNewPodcast(oPodcast): Promise<any> {
        oPodcast.startPlayInitText = 'Willkommen bei %title%. Möchtest du die neueste Folge %newestEpisode% starten?';
        oPodcast.startPlayNewText = 'Willkommen bei %title%. Möchtest du die neueste Folge %newestEpisode% starten?';
        oPodcast.startPlayRepromptText = 'Du kannst mich fragen, welche Folgen es gibt, oder sage einfach: Spiele die aktuelle Folge';
        oPodcast.startResumeText = 'Willkommen bei %title%. Möchtest du die zuletzt gehörte Folge %lastPlayedEpisode% fortsetzen?';
        // tslint:disable-next-line: max-line-length
        oPodcast.startResumeRepromptText = 'Wenn du wissen willst, welche Folgen es gibt, frag mich einfach. Willst du die zuletzt gehörte Folge %lastPlayedEpisode% fortsetzen?';
        oPodcast.finishText = 'Besuch bald wieder unseren Podcast';
        return oPodcast;
    }

    initNewSkill(oSkill) {
        oSkill.sSkillType = 'Podcast';
        return oSkill;
    }

    initNewAction(oAction) {
        oAction.sActionType = 'Podcast';
        return oAction;
    }

    initNewPodcastContent(oPodcastContent) {
        oPodcastContent.sFirstLaunchStartText = 'Willkommen bei %title%. Möchtest du die neueste Folge %newestEpisode% starten?';
        oPodcastContent.sLaunchStartText = 'Willkommen bei %title%. Möchtest du die neueste Folge %newestEpisode% starten?';
        // tslint:disable-next-line: max-line-length
        oPodcastContent.sLaunchStartRepromtText = 'Du kannst mich fragen, welche Folgen es gibt, oder sage einfach: Spiele die aktuelle Folge';
        oPodcastContent.sLaunchResumeText = 'Willkommen bei %title%. Möchtest du die zuletzt gehörte Folge %lastPlayedEpisode% fortsetzen?';
        // tslint:disable-next-line: max-line-length
        oPodcastContent.sNewOrResumeText = 'Willkommen bei %title%. Möchtest du die neue Folge %newestEpisode% starten oder die zuletzt gehörte Folge %lastPlayedEpisode% fortsetzen?';
        // tslint:disable-next-line: max-line-length
        oPodcastContent.sNewOrResumeRepromtText = 'Möchtest du die neue Folge %newestEpisode% starten oder die zuletzt gehörte Folge %lastPlayedEpisode% fortsetzen?';
        // tslint:disable-next-line: max-line-length
        oPodcastContent.sLaunchResumeRepromtText = 'Wenn du wissen willst, welche Folgen es gibt, frag mich einfach. Willst du die zuletzt gehörte Folge %lastPlayedEpisode% fortsetzen?';
        oPodcastContent.sStopText = 'Besuch bald wieder unseren Podcast';
        return oPodcastContent;
    }

    initNewRadioContent(oRadioContent) {
        return oRadioContent;
    }

    initNewRadioEntry(oRadioEntry) {
        return oRadioEntry;
    }

    initNewPodcastActionContent(oPodcastActionContent) {
        return oPodcastActionContent;
    }

    // V1 (old version)
    async listPodcasts(): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const aPodcasts = (await API.graphql(graphqlOperation(listPodcasts, { limit: 1000 }))).data.listPodcasts.items;

            console.log('Success Query listPodcasts', aPodcasts);
            return aPodcasts;
        } catch (oError) {
            console.log('Error Query listPodcasts', oError);
            throw oError;
        }
    }

    async listSkills(): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            const queryResult = await API.graphql(graphqlOperation(listSkills, { limit: 1000 })) as SkillList;
            const aSkills = queryResult.data.listSkills.items;

            console.log('Success Query listSkills', aSkills);
            return aSkills;
        } catch (oError) {
            console.log('Error Query listSkills', oError);
            throw oError;
        }
    }

    async listActions(): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            const queryResult = await API.graphql(graphqlOperation(listActions, { limit: 1000 })) as ActionList;
            const aActions = queryResult.data.listActions.items;

            console.log('Success Query listActions', aActions);
            return aActions;
        } catch (oError) {
            console.log('Error Query listActions', oError);
            throw oError;
        }
    }

    async listPodcastContents(): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const aPodcastContents = (await API.graphql(graphqlOperation(listPodcastContents, { limit: 1000 }))).data.listPodcastContents
                .items;

            console.log('Success Query listPodcastContents', aPodcastContents);
            return aPodcastContents;
        } catch (oError) {
            console.log('Error Query listPodcastContents', oError);
            throw oError;
        }
    }

    async listRadioContents(): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const aRadioContents = (await API.graphql(graphqlOperation(listRadioContents, { limit: 1000 }))).data.listRadioContents
                .items;

            console.log('Success Query listRadioContents', aRadioContents);
            return aRadioContents;
        } catch (oError) {
            console.log('Error Query listRadioContents', oError);
            throw oError;
        }
    }

    async listPodcastActionContents(): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const aPodcastActionContents = (await API.graphql(graphqlOperation(listPodcastActionContents, { limit: 1000 }))).data.listPodcastActionContents.items;

            console.log('Success Query listPodcastActionContents', aPodcastActionContents);
            return aPodcastActionContents;
        } catch (oError) {
            console.log('Error Query listPodcastActionContents', oError);
            throw oError;
        }
    }

    // V1 (old version)
    async podcastTitleUsed(sPodcastTitle: string): Promise<boolean> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const aPodcasts = (await API.graphql(graphqlOperation(listPodcasts, { filter: { podcastTitle: { eq: sPodcastTitle } } }))).data
                .listPodcasts;

            console.log('Success Query podcastTitleUsed', aPodcasts.items);
            return aPodcasts.items.length === 0 ? false : true;
        } catch (oError) {
            console.log('Error Query podcastTitleUsed', oError);
            throw oError;
        }
    }

    async isAlexaSkillNameUsed(sSkillName: string): Promise<boolean> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const aSkills = (await API.graphql(graphqlOperation(listSkills, { filter: { sAlexaSkillName: { eq: sSkillName } } }))).data
                .listSkills.items;

            console.log('Success Query isAlexaSkillNameUsed', aSkills);
            return aSkills.length === 0 ? false : true;
        } catch (oError) {
            console.log('Error Query isAlexaSkillNameUsed', oError);
            throw oError;
        }
    }

    async isGoogleActionNameUsed(sActionName: string): Promise<boolean> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const aActions = (await API.graphql(graphqlOperation(listActions, { filter: { sGoogleActionName: { eq: sActionName } } }))).data.listActions.items;

            console.log('Success Query isGoogleActionNameUsed', aActions);
            return aActions.length === 0 ? false : true;
        } catch (oError) {
            console.log('Error Query isGoogleActionNameUsed', oError);
            throw oError;
        }
    }

    // V1 (old version)
    async deletePodcast(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oPodcast = (await API.graphql(graphqlOperation(deletePodcast, { input: { id: sId } }))).data.deletePodcast;

            console.log('Success Mutation deletePodcast', oPodcast);
            return oPodcast;
        } catch (oError) {
            console.log('Error Mutation deletePodcast', oError);
            throw oError;
        }
    }

    async deleteSkill(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oSkill = (await API.graphql(graphqlOperation(deleteSkill, { input: { id: sId } }))).data.deleteSkill;

            console.log('Success Mutation deleteSkill', oSkill);
            return oSkill;
        } catch (oError) {
            console.log('Error Mutation deleteSkill', oError);
            throw oError;
        }
    }

    async deleteAction(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oAction = (await API.graphql(graphqlOperation(deleteAction, { input: { id: sId } }))).data.deleteAction;

            console.log('Success Mutation deleteAction', oAction);
            return oAction;
        } catch (oError) {
            console.log('Error Mutation deleteAction', oError);
            throw oError;
        }
    }

    async deletePodcastContent(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oPodcastContent = (await API.graphql(graphqlOperation(deletePodcastContent, { input: { id: sId } }))).data
                .deletePodcastContent;

            console.log('Success Mutation deletePodcastContent', oPodcastContent);
            return oPodcastContent;
        } catch (oError) {
            console.log('Error Mutation deletePodcastContent', oError);
            throw oError;
        }
    }

    async deleteRadioContent(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oRadioContent = (await API.graphql(graphqlOperation(deleteRadioContent, { input: { id: sId } }))).data
                .deleteRadioContent;

            console.log('Success Mutation deleteRadioContent', oRadioContent);
            return oRadioContent;
        } catch (oError) {
            console.log('Error Mutation deleteRadioContent', oError);
            throw oError;
        }
    }

    async deletePodcastActionContent(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const oPodcastActionContent = (await API.graphql(graphqlOperation(deletePodcastActionContent, { input: { id: sId } }))).data.deletePodcastActionContent;

            console.log('Success Mutation deletePodcastActionContent', oPodcastActionContent);
            return oPodcastActionContent;
        } catch (oError) {
            console.log('Error Mutation deletePodcastActionContent', oError);
            throw oError;
        }
    }

    /**
     * SMAPI Queries/Mutations
     */

    async createCatalogVersion(sCatalogID: string, sJsonUrl: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();

            // prettier-ignore
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const result = (await API.graphql(graphqlOperation(smapiCreateCatalogVersion, { sCatalogID, sJsonUrl }))).data.SMAPICreateCatalogVersion;

            console.log('Success Mutation createCatalogVersion', result);
            return result;
        } catch (oError) {
            console.log('Error Mutation createCatalogVersion', oError);
            throw oError;
        }
    }

    /**
     * PodcastAnalytics Type Queries/Mutations
     */

    async getPodcastAnalytics(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            const oPodcastAnalytics = (await API.graphql(graphqlOperation(getPodcastAnalytics, { sSkillID: sId }))).data
                .getPodcastAnalytics;

            console.log('Success Query getPodcastAnalytics', oPodcastAnalytics);
            return oPodcastAnalytics;
        } catch (oError) {
            console.log('Error Query getPodcastAnalytics', oError);
            throw oError;
        }
    }

    /**
     * PodcastAnalyticsEpisode Type Queries/Mutations
     */

    async listPodcastAnalyticsEpisodes(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const aPodcastAnalyticsEpisodes = (await API.graphql(graphqlOperation(listPodcastAnalyticsEpisodes, { sSkillID: sId }))).data
                .listPodcastAnalyticsEpisodes;

            console.log('Success Query listPodcastAnalyticsEpisodes', aPodcastAnalyticsEpisodes);
            return aPodcastAnalyticsEpisodes;
        } catch (oError) {
            console.log('Error Query listPodcastAnalyticsEpisodes', oError);
            throw oError;
        }
    }

    /**
     * PodcastAnalyticsUser Type Queries/Mutations
     */

    async listPodcastAnalyticsUsers(sId: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // @ts-ignore
            // tslint:disable-next-line: max-line-length
            const aPodcastAnalyticsUsers = (await API.graphql(graphqlOperation(listPodcastAnalyticsUsers, { sSkillID: sId }))).data
                .listPodcastAnalyticsUsers;

            console.log('Success Query listPodcastAnalyticsUsers', aPodcastAnalyticsUsers);
            return aPodcastAnalyticsUsers;
        } catch (oError) {
            console.log('Error Query listPodcastAnalyticsUsers', oError);
            throw oError;
        }
    }

    /**
     * PodcastAnalytics Type Queries/Mutations
     */

    async MP3Conversion(sTitle: string, sMP3: string): Promise<any> {
        try {
            await Auth.currentAuthenticatedUser();
            // tslint:disable-next-line: object-literal-shorthand
            const result = await API.graphql(graphqlOperation(triggerMp3Conversion, { sTitle: sTitle, sMP3: sMP3 }));
            // @ts-ignore
            const oMP3ConversionResult = result.data.triggerMp3Conversion;

            console.log('Success Query triggerMp3Conversion', oMP3ConversionResult);
            return oMP3ConversionResult;
        } catch (oError) {
            console.log('Error Query triggerMp3Conversion', oError);
            throw oError;
        }
    }
    /**
     * Other methods
     */

    // delete all attributes, which are null undefined or empty (else mutations and queries throw an error)
    private cleanObject(oObject) {
        for (const sAttribute in oObject) {
            if (oObject[sAttribute] === undefined) {
                delete oObject[sAttribute];
            } else if (oObject[sAttribute] === '') {
                oObject[sAttribute] = null;
            } else if (oObject[sAttribute] && oObject[sAttribute].attributes > 0) {
                oObject[sAttribute] = this.cleanObject(oObject[sAttribute]);
            }
        }
        return oObject;
    }
}

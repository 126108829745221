import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PodcastActionContentType } from '../shared/PodcastActionContentType';
import { ActionType } from '../shared/ActionType';
import {
  checkUrl,
  checkEmail,
  checkAudioType
} from '../shared/Validations';
import * as ErrorMessages from '../shared/ErrorMessages';
import * as Toolkit from '../shared/Toolkit';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppsyncService } from '../services/appsync.service';
import { S3Service } from '../services/s3.service';

@Component({
  selector: 'app-vcms-podcast-action-content',
  templateUrl: './vcms-podcast-action-content.component.html',
  styleUrls: ['./vcms-podcast-action-content.component.scss']
})
export class VcmsPodcastActionContentComponent implements OnInit {
  formGroup: FormGroup;

  sCurrentId: string;
  busy: Promise<any>;
  busymessage: string;
  oAction: ActionType = {};
  oPodcastActionContent: PodcastActionContentType = {};

  getFilePath = Toolkit.getFilePath;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appsyncService: AppsyncService,
    private s3Service: S3Service,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {

    this.initEmptyData();
    this.route.params.subscribe((params = {}) => {
      this.sCurrentId = params.id;
      this.getData();
    });
  }

  ngOnInit() {
    this.busymessage = 'Speichern...';
  }

  /**
   * Initialization of variables
   */

  async initGeneral(oPodcastActionContent) {
    try {
      this.formGroup = this.formBuilder.group({
        id: [oPodcastActionContent.id],
        sPodcastRssUrl: [oPodcastActionContent.sPodcastRssUrl, [Validators.required, checkUrl]],
        sContactEmail: [oPodcastActionContent.sContactEmail, [Validators.required, checkEmail]],
        bNeedEpisodePrefix: [oPodcastActionContent.bNeedEpisodePrefix, [Validators.required]],
        sFirstLaunchAudioPath: [oPodcastActionContent.sFirstLaunchAudioPath],
        sFirstLaunchAudioInput: [null, [checkAudioType.bind(this)]],
        sLaunchAudioPath: [oPodcastActionContent.sLaunchAudioPath],
        sLaunchAudioInput: [null, [checkAudioType.bind(this)]],
        sLaunchRepromptAudioPath: [oPodcastActionContent.sLaunchRepromptAudioPath],
        sLaunchRepromptAudioInput: [null, [checkAudioType.bind(this)]],

        sStopAudioPath: [oPodcastActionContent.sStopAudioPath],
        sStopAudioInput: [null, [checkAudioType.bind(this)]],
        sFallbackAudioPath: [oPodcastActionContent.sFallbackAudioPath],
        sFallbackAudioInput: [null, [checkAudioType.bind(this)]],

        sEndOfPlaylistAudioPath: [oPodcastActionContent.sEndOfPlaylistAudioPath],
        sEndOfPlaylistAudioInput: [null, [checkAudioType.bind(this)]],
        sEndOfPlaylistExtendedAudioPath: [oPodcastActionContent.sEndOfPlaylistExtendedAudioPath],
        sEndOfPlaylistExtendedAudioInput: [null, [checkAudioType.bind(this)]],

        sNotificationPermissionConfirmedAudioPath: [oPodcastActionContent.sNotificationPermissionConfirmedAudioPath],
        sNotificationPermissionConfirmedAudioInput: [null, [checkAudioType.bind(this)]],
        sNotificationPermissionNotPossibleAudioPath: [oPodcastActionContent.sNotificationPermissionNotPossibleAudioPath],
        sNotificationPermissionNotPossibleAudioInput: [null, [checkAudioType.bind(this)]],
        sMessageRecordingAudioPath: [oPodcastActionContent.sMessageRecordingAudioPath],
        sMessageRecordingAudioInput: [null, [checkAudioType.bind(this)]],
        sMessageReceivedAudioPath: [oPodcastActionContent.sMessageReceivedAudioPath],
        sMessageReceivedAudioInput: [null, [checkAudioType.bind(this)]],

        sAskForInitialGuidingAudioPath: [oPodcastActionContent.sAskForInitialGuidingAudioPath],
        sAskForInitialGuidingAudioInput: [null, [checkAudioType.bind(this)]],
        sInitialGuidingAudioPath: [oPodcastActionContent.sInitialGuidingAudioPath],
        sInitialGuidingAudioInput: [null, [checkAudioType.bind(this)]],
        sNoInitialGuidingAudioPath: [oPodcastActionContent.sNoInitialGuidingAudioPath],
        sNoInitialGuidingAudioInput: [null, [checkAudioType.bind(this)]],
        sAskForMoreActionsHelpAudioPath: [oPodcastActionContent.sAskForMoreActionsHelpAudioPath],
        sAskForMoreActionsHelpAudioInput: [null, [checkAudioType.bind(this)]],

        sEpisodeIntroAudioPath: [oPodcastActionContent.sEpisodeIntroAudioPath],
        sEpisodeIntroAudioInput: [null, [checkAudioType.bind(this)]],
        sEpisodeNotFoundAudioPath: [oPodcastActionContent.sEpisodeNotFoundAudioPath],
        sEpisodeNotFoundAudioInput: [null, [checkAudioType.bind(this)]],

        sEpisodeListPrefixAudioPath: [oPodcastActionContent.sEpisodeListPrefixAudioPath],
        sEpisodeListPrefixAudioInput: [null, [checkAudioType.bind(this)]],
        sEpisodeSearchListPrefixAudioPath: [oPodcastActionContent.sEpisodeSearchListPrefixAudioPath],
        sEpisodeSearchListPrefixAudioInput: [null, [checkAudioType.bind(this)]],
      });
    } catch (oError) { }
  }

  /**
   * Setter for switch
   */

  // Set value for bNeedEpisodePrefix
  onSwitchChange(oEvent) {
    this.formGroup.controls.bNeedEpisodePrefix.setValue(oEvent.checked);
  }


  /**
   * Other methods
   */

  getErrorMessage(form, type) {
    return ErrorMessages[type](this.formGroup.get(form));
  }

  // Map value of button to variable (checked or not checked)
  // changeDeselectButton(oEvent, sButton) {
  //   this[sButton] = oEvent.checked;
  // }

  // Update Podcast Content
  updatePodcastActionContent() {
    try {
      this.busy = new Promise(async (resolve) => {
        const oData = this.formGroup.value;

        const mFilesToUpload = new Map();

        const aInputs = [
          'sFirstLaunchAudio',
          'sLaunchAudio',
          'sLaunchRepromptAudio',
          'sStopAudio',
          'sFallbackAudio',
          'sEndOfPlaylistAudio',
          'sEndOfPlaylistExtendedAudio',
          'sNotificationPermissionConfirmedAudio',
          'sNotificationPermissionNotPossibleAudio',
          'sMessageRecordingAudio',
          'sMessageReceivedAudio',
          'sAskForInitialGuidingAudio',
          'sInitialGuidingAudio',
          'sNoInitialGuidingAudio',
          'sAskForMoreActionsHelpAudio',
          'sEpisodeIntroAudio',
          'sEpisodeNotFoundAudio',
          'sEpisodeListPrefixAudio',
          'sEpisodeSearchListPrefixAudio'
        ];

        aInputs.forEach(sInput => {
          if (oData[sInput + 'Input'] && oData[sInput + 'Input'] !== null) {
            mFilesToUpload.set(sInput, oData[sInput + 'Input']);
          } else {
            delete oData[sInput + 'Input'];
          }
        });

        const fUpdate = async oInput => {
          await this.appsyncService.updatePodcastActionContent(oInput);
          this.router.navigate(['/reload'], { queryParams: { path: `/action/podcast/${oInput.id}` } });
        };

        if (mFilesToUpload.size > 0) {
          mFilesToUpload.forEach(async (oInput, sFileName) => {
            this.busymessage = `Uploading ${oInput.fileNames}`;
            const sKey = await this.s3Service.uploadFile(oInput.files[0], `${this.oAction.sGoogleActionName}/action/${oInput.fileNames}`);
            this.busymessage = `Converting ${oInput.fileNames}`;
            await this.appsyncService.MP3Conversion(`${this.oAction.sGoogleActionName}/action`, oInput.fileNames);

            delete oData[`${sFileName}Input`];
            oData[`${sFileName}Path`] = sKey;

            mFilesToUpload.delete(sFileName);

            if (mFilesToUpload.size === 0) {
              console.log('All Files uploaded');

              // await this.convertMP3s(oData.podcastTitle, mFilesToUpload);
              console.log('All Files converted');

              await fUpdate(oData);
            }
          });
        } else {
          await fUpdate(oData);
        }
      });
    } catch (error) {
      this.snackBar.open('Es konnte nicht erfolgreich gespeichert werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

  // Init the action
  async initEmptyData() {
    try {
      this.oPodcastActionContent = await this.appsyncService.emptyNewPodcastActionContent();
      this.oAction = await this.appsyncService.emptyNewAction();
      this.initGeneral(this.oPodcastActionContent);
    } catch (oError) { }
  }

  // Get the podcast with ID from URL
  async getData() {
    try {
      this.oPodcastActionContent = await this.appsyncService.getPodcastActionContent(this.sCurrentId);
      this.oAction = await this.appsyncService.getAction(this.sCurrentId);
      this.initGeneral(this.oPodcastActionContent);
    } catch (oError) {
      this.snackBar.open('Podcast konnte nicht geladen werden', 'Schließen', {
        duration: 5000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom'
      });
    }
  }

  clearFile(sFormControl) {
    this.formGroup.get(sFormControl).reset();
  }
}
